import { all, takeLatest, put } from 'redux-saga/effects';
import { loginSuccess, loginFailed, clearAll } from './actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import Notify from '../../../utils/notification';

function* login(action) {
  const { username, password } = action.payload;

  const notify = Notify('Realizando login', true);
  try {
    yield api.login(username, password);
    const token = api._token;
    const { admin } = yield api.me();
    if (!admin) {
      const message = 'Usuário não tem permissão de Administrador!';
      yield put(loginFailed(message));
      return;
    }
    yield put(loginSuccess(token));
    notify.success('Login realizado com sucesso');
    history.push('/lives');
  } catch (error) {
    notify.error('Falha ao realizar login');
    let message = 'Sem acesso a internet';
    if (error.response) {
      switch (error.response.status) {
        case 401:
          message = 'Login ou senha incorretos';
          break;
        default:
          message = `Falha ao realizar login, status de erro: ${error.response.status}`;
      }
    }
    console.log('login error', error, message);
    yield put(loginFailed(message));
  }
}

function* logout() {
  const notify = Notify('Realizando logout');
  try {
    yield api.logout();
    notify.success('Logout realizado com sucesso');
  } catch (error) {
    notify.error('Falha ao realizar logout');
    const message = defaultErrorHandler(error, { default: 'Falha ao realizar logout' });
    console.log('logout error', error, message);
  } finally {
    history.push('/');
  }
}

function* setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.login;
  if (token) {
    api.setToken(token);
  } else {
    yield put(clearAll());
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('LOGIN_REQUEST', login),
  takeLatest('LOGOUT', logout)
]);
