/* eslint-disable indent */
import {
    all, put, takeLatest, takeEvery, select, takeLeading, delay,
} from 'redux-saga/effects';

import axios from 'axios';
import history from '../../../services/history';
import api from '../../../services/api';
import * as roomActions from './actions';
import { defaultErrorHandler } from '../../utilities';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';
import { UPLOAD_VIDEO_STATUS } from '../../../utils/constants';
import Notify from '../../../utils/notification';

function* getRooms() {
    try {
        const { channels } = yield api.rooms.getUserRooms();
        yield put(roomActions.setRooms(channels));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('CHANNEL_FAILREQUESTROOMS') });
    }
}

function* getUserRooms() {
    try {
        const { channels } = yield api.rooms.getUserRooms();
        yield put(roomActions.setRooms(channels));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('CHANNEL_FAILREQUESTROOM') });
    }
}

function* getRoom({ payload: roomID }) {
    try {
        const room = yield api.rooms.getRoom(roomID);
        yield put(roomActions.setRoom(room));
        if (room.live) {
            yield put(roomActions.enterChat(roomID));
        }
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('CHANNEL_FAILREQUESTROOM'),
            404: translate('CHANNEL_ROOMNOTFOUND'),
        });

        if (error.response) {
            switch (error.response.status) {
                case 403: {
                    const { courseId, roomId, dataTermUrl } = error.response.data;
                    history.push(`/terms/${courseId}/${roomId}/${encodeURIComponent(dataTermUrl)}`);
                    break;
                }
                case 404: {
                    history.replace('/');
                    break;
                }
                default:
                    break;
            }
        }
    }
}

function* getRoomEdit({ payload: { roomId, currentRoom } }) {
    if (roomId) {
        try {
            if (currentRoom?.recorded) {
                const room = yield api.rooms.getRoom(roomId);
                const { startDate, endDate } = currentRoom;
                yield put(roomActions.setEditRoom({ ...room, endDate, startDate }));
            } else {
                const room = yield api.rooms.getRoomEdit(roomId);
                yield put(roomActions.setEditRoom(room));
            }
        } catch (error) {
            yield put({ type: actions.ROOM_FAIL });
            defaultErrorHandler(error, {
                default: translate('CHANNEL_FAILREQUESTROOM'),
                404: translate('CHANNEL_ROOMNOTFOUND'),
            });
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        history.replace('/');
                        break;
                    default:
                        break;
                }
            }
        }
    }
    yield put({ type: actions.ROOM_FAIL });
}

function* editRoom({ payload }) {
    try {
        yield api.rooms.editRoom(payload.room, payload.id);
        yield put({ type: 'USER_ROOMS_REQUEST' });
        Notify.success(translate('CHANGES_SUCCESS'));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                case 403:
                    Notify.warn(translate('UNABLE_TO_EDIT_VIDEO'));
                    break;
                default:
                    Notify.warn(translate('EDIT_ROOM_FAIL'));
                    break;
            }
        }
    }
}

function* createRoom({ payload }) {
    try {
        yield api.rooms.createRoom(payload);
        yield put(roomActions.getChannelRooms(payload?.course_id));
    } catch (error) {
        yield put({ type: actions.ROOM_FAIL });
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* exitRoom() {
    try {
        const selectedRoom = yield select(state => state.room.selectedRoom);
        if (selectedRoom && selectedRoom.id) {
            yield put(roomActions.exitChat(selectedRoom.id));
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_EXITROOM') });
    } finally {
        yield put(roomActions.clearSelected());
    }
}

function* activeGoLive({ payload }) {
    const { roomId, noPresenterErrorCallBack } = payload;
    try {
        yield api.rooms.activeGoLive(roomId);
        yield put({ payload: roomId, type: actions.ROOM_EDIT_SELECT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                case 403:
                    if (noPresenterErrorCallBack) {
                        noPresenterErrorCallBack();
                    }
                    break;
                default:
                    break;
            }
        }
    }
}

function* activeEndLive({ payload: roomId }) {
    try {
        yield api.rooms.activeEndLive(roomId);
        yield put({ payload: roomId, type: actions.ROOM_EDIT_SELECT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* isRoomLive({ payload: roomId }) {
    try {
        const live_status = yield api.rooms.isRoomLive(roomId);
        yield put({ payload: live_status, type: actions.ROOM_SET_STATUS_ROOM });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomEngagement({ payload: roomId }) {
    try {
        const data = yield api.rooms.getRoomEngagement(roomId);
        yield put({ payload: data, type: actions.ROOM_SET_ENGAGEMENT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomEventLog({ payload: roomId }) {
    try {
        const { data } = yield api.rooms.getRoomEventLog(roomId);
        yield put({ payload: data, type: actions.ROOM_SET_EVENTLOG });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomGraph({ payload }) {
    try {
        const { roomID, startDate, endDate } = payload;
        const { data } = yield api.rooms.getRoomGraph(roomID, startDate, endDate);
        yield put({ payload: data, type: actions.ROOM_SET_GRAPH });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomGeolocalization({ payload }) {
    try {
        const { roomID, startDate, endDate } = payload;
        const data = yield api.rooms.getRoomGeolocalization(roomID, startDate, endDate);
        yield put({ payload: data, type: actions.ROOM_SET_GEOLOCALIZATION });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/');
                    break;
                default:
                    break;
            }
        }
    }
}

function* sendMessage({ payload: { roomID, message, type } }) {
    try {
        if (type === 1) { // questions
            yield api.rooms.sendQuestion(roomID, message);
        } else if (type === 2) { //
            yield api.rooms.sendMessageMonitorChat(roomID, message);
        } else if (type === 3) {
            yield api.rooms.sendMessageTeamChat(roomID, message);
        } else {
            yield api.rooms.sendMessage(roomID, message);
        }
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('WARNINGS_MESSAGES_FAIL'),
            400: translate('WARNINGS_MESSAGES_NOTALLOWED'),
            403: translate('PERMISSION_SENDMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
            429: translate('WARNINGS_MESSAGES'),
        });
    }
}

function* enterChat({ payload: roomID }) {
    try {
        const chatUserType = yield api.rooms.enterChat(roomID);
        yield put({ type: actions.ROOM_SET_CHAT_USER_TYPE, payload: { chatUserType } });
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_ENTERROOM'),
            403: translate('PERMISSION_ENTERCHAT'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* exitChat({ payload: roomID }) {
    try {
        yield api.rooms.exitChat(roomID);
        yield put(roomActions.setChatTabs(null));
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_EXITROOM'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* replyMessage({ payload: { roomID, text, originalMessageId } }) {
    try {
        yield api.rooms.sendReply(roomID, text, originalMessageId);
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('WARNINGS_MESSAGES_FAIL'),
            403: translate('WARNINGS_MESSAGES_PERMISSIONS'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* roomPinMesssage({ payload: { roomID, messageID } }) {
    try {
        yield api.rooms.pinMessage(roomID, messageID);
        yield put({ type: 'ROOM_REQUEST', payload: roomID });
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_PINMESSAGE'),
            403: translate('PERMISSION_PINMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* roomUnpinMesssage({ payload: { roomID, messageID } }) {
    try {
        yield api.rooms.unpinMessage(roomID, messageID);
        yield put({ type: 'ROOM_REQUEST', payload: roomID });
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_UNPINMESSAGE'),
            403: translate('PERMISSION_UNPINMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* setChatEnableRoom({ payload }) {
    try {
        const { selectedRoom } = yield select(({ room }) => room);
        if (selectedRoom.id === payload.room.roomId) {
            yield put(
                {
                    type: actions.ROOM_CHAT_SET_CHAT_ENABLE_ROOM,
                    payload: { isChatEnabled: payload.room.enabled },
                },
            );
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_ENABLE_ROOM') });
    }
}

function* setChatEnableTeam({ payload }) {
    try {
        const { enabled, roomId } = payload;
        const { selectedRoom } = yield select(({ room }) => room);
        if (payload && selectedRoom.id === roomId) {
            yield put(
                {
                    type: actions.ROOM_CHAT_SET_ENABLE_TEAM,
                    payload: { isEnabledTeamChat: enabled },
                },
            );
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_ENABLE_TEAM') });
    }
}

function* handleRoomRequests(action) {
    if (action.type === 'ROOM_REQUEST') {
        yield getRoom(action);
    } else if (action.type === 'ROOM_EXIT') {
        yield exitRoom(action);
    }
}

function* setTermOfUsage({ payload }) {
    try {
        const { courseId, userId, roomId } = payload;
        yield api.rooms.setUserTerm(courseId, userId);
        history.push(`/course/${roomId}`);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_SET_TERMS') });
    }
}

function* setChatEnabled({ payload }) {
    try {
        const { roomID, chatEnabled } = payload;
        yield api.rooms.setChatEnabled(roomID, chatEnabled);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('FAIL_TO_SET_CHAT') });
    }
}

function* fetchStatus(videoId) {
    return yield api.rooms.getUploadStatus(videoId);
}

function* getPollingStatus(videoId) {
    const { isUploadingInBackground } = yield select(({ room }) => room);
    return isUploadingInBackground.some(video => video.id === videoId);
}

/**
 *
 * @param {{ payload: { videoToUpload: File, roomObject: {} } }} param0
 */
export function* uploadVideo({ payload }) {
    try {
        const { roomObject, videoToUpload } = payload;
        const uploadFileName = videoToUpload.name;
        Notify.success(translate('UPLOAD_VIDEO_PREPARING'));
        const data = yield api.rooms.getVideoUploadUrl(roomObject);
        const tempRoom = {
            endDate: roomObject?.end_date,
            id: data.room_id,
            image: roomObject?.thumbnail_url,
            live: false,
            name: roomObject?.room_name,
            recorded: true,
            startDate: roomObject?.start_date,
            status: UPLOAD_VIDEO_STATUS.UPLOADING,
            channel: roomObject.course_id,
        };
        const { selectedChannelRooms, isUploadingInBackground } = yield select(({ room }) => room);
        yield put(roomActions.getChannelRoomsSuccess([...selectedChannelRooms, tempRoom]));
        yield put(roomActions.setIsUploadingInBackground([...isUploadingInBackground, tempRoom]));

        let chunkPosition = 1;
        const blocksize = 10240000;
        const chunks = Math.ceil(videoToUpload.size / blocksize, blocksize);
        let formData = new FormData();
        let slice;
        const param = { 'Content-Type': 'multipart/form-data', params: { format: 1 } };

        const uploadUrl = data.url;
        if (uploadUrl.includes('format=')) {
            delete param.params.format;
        }

        while (chunkPosition <= chunks) {
            const blockStart = (chunkPosition - 1) * blocksize;
            slice = videoToUpload.slice(blockStart, chunkPosition * blocksize, videoToUpload.type);
            formData = new FormData();
            formData.append('fileData', slice, uploadFileName);
            formData.append('ks', data.ks);
            formData.append('uploadTokenId', data.upload_token_id);
            formData.append('resume', chunkPosition !== 1);
            formData.append('resumeAt', chunkPosition === 1 ? '0' : '-1');
            formData.append('finalChunk', chunkPosition === chunks);
            const response = yield axios.post(uploadUrl, formData, param);
            if (!response.data || response.data.objectType === 'KalturaAPIException' || response.data.objectType !== 'KalturaUploadToken') {
                Notify.error(translate().replace('[ROOM_NAME]', roomObject?.room_name).replace('[ERROR_DATA]', JSON.stringify(response.data)));
                console.error('Chunk upload error', response.data);
                return;
            }
            yield put({
                type: actions.ROOM_UPLOAD_VIDEO_UPDATE_PROGRESS,
                payload: (((chunkPosition / chunks) * 100).toFixed(2)),
            });
            chunkPosition += 1;
        }
        yield put({
            type: actions.ROOM_UPLOAD_VIDEO_UPDATE_PROGRESS,
            payload: 0,
        });

        while (yield getPollingStatus(data.room_id)) {
            try {
                const { isUploadingInBackground: videos, selectedChannelRooms } = yield select(({ room }) => room);
                const { status } = yield fetchStatus(data.room_id);

                if (status === UPLOAD_VIDEO_STATUS.READY) {
                    yield put(roomActions.setIsUploadingInBackground(videos.filter(video => video.id !== data.room_id)));
                    Notify.success(`O video ${roomObject?.room_name} foi concluído.`);
                }

                if (status === UPLOAD_VIDEO_STATUS.UPLOAD_ERROR) {
                    yield put(roomActions.setIsUploadingInBackground(videos.filter(video => video.id !== data.room_id)));
                    Notify.error(`O upload do vídeo ${roomObject?.room_name} falhou!`);
                }

                if (status) {
                    yield put(roomActions.getChannelRoomsSuccess(selectedChannelRooms.map(video => (video.id === data.room_id ? ({ ...video, status }) : video))));
                }
                yield delay(10000);
            } catch (error) {
                yield delay(10000);
            }
        }
    } catch (error) {
        defaultErrorHandler(error, { default: translate('UPLOAD_VIDEO_FAILURE') });
    }
}

function* getChannels() {
    try {
        const channels = yield api.rooms.getChannels();
        yield put(roomActions.setChannels(channels.channels));
    } catch (error) {
        defaultErrorHandler(error, { default: translate('GET_CHANNELS_FAILURE') });
    }
}

function* getChannelRooms({ payload }) {
    try {
        const { rooms, hasGamification, extra_user_supports } = yield api.rooms.getChannelRooms(payload);
        const { isUploadingInBackground, selectedChannel } = yield select(({ room }) => room);
        yield put(roomActions.setHasExtraUsers(extra_user_supports));
        yield put(roomActions.setHasgamification(hasGamification));
        if (isUploadingInBackground.length > 0) {
            yield put(roomActions.getChannelRoomsSuccess(rooms.concat(isUploadingInBackground.filter(video => video.channel === selectedChannel.id))));
        } else {
            yield put(roomActions.getChannelRoomsSuccess(rooms));
        }
    } catch (error) {
        yield put(roomActions.getChannelRoomsFailed());
        defaultErrorHandler(error, { default: translate('GET_COURSE_FAILURE') });
    }
}

function* mutedParticipantRequest({ payload }) {
    try {
        const { roomId, userEmail } = payload;
        yield api.rooms.muteParticipant(roomId, userEmail);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('MUTED_PARTICIPANT_ERROR') });
    }
}

function* unmutedParticipantRequest({ payload }) {
    try {
        const { roomId, userEmail } = payload;
        yield api.rooms.unmuteParticipant(roomId, userEmail);
    } catch (error) {
        defaultErrorHandler(error, { default: translate('UNMUTED_PARTICIPANT_ERROR') });
    }
}

export default all([
    takeLatest(actions.GET_CHANNELS_FOR_SIDEBAR, getChannels),
    takeLatest('ROOMS_REQUEST', getRooms),
    takeLatest('USER_ROOMS_REQUEST', getUserRooms),
    takeLatest(['ROOM_REQUEST', 'ROOM_EXIT'], handleRoomRequests),
    takeLatest('ROOM_SEND_MESSAGE', sendMessage),
    takeLatest('ROOM_ENTER_CHAT', enterChat),
    takeLeading('ROOM_EXIT_CHAT', exitChat),
    takeLatest(actions.ROOM_EDIT_SELECT, getRoomEdit),
    takeLatest(actions.ROOM_IS_LIVE, isRoomLive),
    takeLatest(actions.ROOM_REPLY_MESSAGE, replyMessage),
    takeLatest(actions.ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM, setChatEnableRoom),
    takeLatest(actions.CHANGE_TEAM_CHAT, setChatEnableTeam),
    takeLatest(actions.ROOM_CREATE, createRoom),
    takeLatest(actions.ROOM_EDIT, editRoom),
    takeLatest(actions.ROOM_GOLIVE, activeGoLive),
    takeLatest(actions.ROOM_ENDLIVE, activeEndLive),
    takeLatest(actions.ROOM_ENGAGEMENT, getRoomEngagement),
    takeLatest(actions.ROOM_EVENTLOG, getRoomEventLog),
    takeLatest(actions.ROOM_GRAPH, getRoomGraph),
    takeLatest(actions.ROOM_GEOLOCALIZATION, getRoomGeolocalization),
    takeLatest(actions.ROOM_PIN_MESSAGE, roomPinMesssage),
    takeLatest(actions.ROOM_UNPIN_MESSAGE, roomUnpinMesssage),
    takeLatest(actions.ROOM_SET_CHAT_ENABLED, setChatEnabled),
    takeLatest(actions.ACCEPT_TERMS_OF_USAGE, setTermOfUsage),
    takeEvery(actions.ROOM_VIDEO_UPLOAD, uploadVideo),
    takeLatest(actions.GET_COURSE_REQUEST, getChannelRooms),
    takeLatest(actions.MUTED_PARTICIPANT_REQUEST, mutedParticipantRequest),
    takeLatest(actions.UNMUTED_PARTICIPANT_REQUEST, unmutedParticipantRequest),
]);
