import {
  Container,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { useState} from 'react';
import Page from '../../components/Page';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ManagerRooms from 'src/components/ManagerSettings/rooms';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import ManagerLogs from 'src/components/ManagerSettings/logs';
import ManagerConfigs from 'src/components/ManagerSettings/configs';
import { useEffect } from 'react';


export default function ManagerSettings() {
  const {breadcrumbs} = useBreadcrumbs();
  const [value, setValue] = useState(0);
  const [showRooms, setShowRooms] = useState(true);
  const [showLogs, setShowLogs] = useState(false);
  const [showConfigs, setShowConfigs] = useState(false);

  const handleTabChange = () => {
    switch (value) {
      case 0 :
      setShowRooms(true)
      setShowLogs(false)
      setShowConfigs(false)
      break
      case 1 :
      setShowRooms(false)
      setShowLogs(true)
      setShowConfigs(false)
      break
      case 2 :
      setShowRooms(false)
      setShowLogs(false)
      setShowConfigs(true)
      break  
    }
  };

  useEffect (()=> {
    handleTabChange();
  }, [value])

  return (
      <Page title='Manager'>
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleTabChange} centered>
              <Tab label="Salas" onClick={(e) => setValue(0)} />
              <Tab label="Logs" onClick={(e) => setValue(1)}/>
              <Tab label="Configurações" onClick={(e) => setValue(2)}/>
            </Tabs>
          </Box>
          {showRooms && <ManagerRooms/>}
          {showLogs && <ManagerLogs/>}
          {showConfigs && <ManagerConfigs/>}
        </Container>
      </Page>
    )
} 