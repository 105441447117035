
import { Button } from "@mui/material";
import { Icon } from '@iconify/react';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import Modal from "src/components/Modal";
import { useDispatch } from 'react-redux';
import { deleteRoom } from "src/store/modules/managerSettings/actions";

export default function ManagerRoomModal({ room, open, title, onClose, maxWidth='xs' }) {
    const dispatch = useDispatch();

    

    return (
            <Modal 
                open={open}
                title={title}
                onClose={onClose}
                maxWidth='xs'
                >
                <h4 style={{color: 'red'}}>Está é uma operação irreversível!</h4>
                <strong>Tem certeza que deseja deletar a sala {room.id} ?</strong>
                <div style={{paddingTop: '1em'}}>
                    <Button
                        variant="contained"
                        component="button"
                        startIcon={<Icon icon={checkmarkCircle2Outline} />}
                        onClick={() => {
                            dispatch(deleteRoom(room.id));
                         }
                        }
                    >Sim</Button>
                </div>
            </Modal>
    )
}