import { all, takeLatest, put } from 'redux-saga/effects';
import { changePasswordSuccess, changePasswordFailed, verifyRecoverCodeSuccess, verifyRecoverCodeFailed } from './actions';
import Notify from '../../../utils/notification';
import history from '../../../services/history';
import api from '../../../services/api';

function* changePassword({ payload }) {
    const { email, voucherId } = payload;
    try {
        yield api.recover_password.recoverPassword({ email });
        yield put(changePasswordSuccess());
        Notify.success('Enviado');
        history.push({
            pathname: voucherId ? `/checkEmail/${voucherId}` : '/codeVerification',
            state: email,
        });
    } catch (error) {
        let message = 'Sem acesso a internet';
        if (error.response) {
            switch (error.response.status) {
            case 404:
                message = 'E-MAIL NÃO ENCONTRADO';
                break;
            case 403:
                message = 'AGUARDE 1 MINUTO PARA REENVIAR O CÓDIGO.';
                break;
            default:
                message = 'Falha ao restaurar senha';
            }
        }
        console.log('login error', error, message);
        yield put(changePasswordFailed(message));
    }
}

function* newPassword(action) {
    const { password, token } = action.payload;
    try {
        yield api.recover_password.changePassword(password, token);
        yield put(changePasswordSuccess());
        history.push('/successNewPassword');
    } catch (error) {
        let message = 'Sem acesso a internet';
        if (error.response) {
            switch (error.response.status) {
            case 401:
                message = 'Credenciais de acesso inválida, expirada ou inexistente';
                history.push('/failedNewPassword');
                break;
            default:
                message = 'Falha ao restaurar senha';
            }
        }
        console.log('login error', error, message);
        yield put(changePasswordFailed(message));
    }
}

function* verifyCodeRecover({ payload }) {
    const { email, code } = payload;
    try {
        const data = yield api.recover_password.verifyRecoverCode(email, code);
        yield put(verifyRecoverCodeSuccess());
        history.push({ pathname: `/newPassword/${data.token}` });
    } catch (error) {
        let message = 'Sem acesso a internet';
        if (error.response) {
            switch (error.response.status) {
            case 400:
                message = 'CÓDIGO DE VERIFICAÇÃO INVÁLIDO';
                break;
            case 401:
                message = 'CÓDIGO EXPIRADO';
                break;
            case 403:
                message = 'NÚMERO MÁXIMO DE TENTATIVAS ALCANÇADOS';
                break;
            default:
                message = 'Falha ao restaurar senha';
            }
        }
        console.log('code recover error', error, message);
        yield put(verifyRecoverCodeFailed(message));
    }
}

export default all([
    takeLatest('VERIFY_RECOVER_CODE_REQUEST', verifyCodeRecover),
    takeLatest('CHANGE_PASSWORD_REQUEST', changePassword),
    takeLatest('NEW_PASSWORD_REQUEST', newPassword),

]);
