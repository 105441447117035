import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 3.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #212032 !important;
  border-radius: 10px;
  margin-bottom: 1.5rem;

  &:hover {
    border: 1px solid #cf8b4c;
  }

  &:focus-within {
    border: 1px solid #cf8b4c;
  }
`;

export const AInput = styled.input.attrs({ color: '#fff' })`
  width: 95% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0;
  background-color: #212032 !important;
  border-radius: 10px !important;
  filter: none;

  @media (max-width: 769px) {
    width: 90% !important;
  }

  @media (max-width: 375px) {
    width: 88% !important;
  }
`;

export const ImageIco = styled.img`
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 0 1rem;
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;
