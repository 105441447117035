export default class Rooms {
    constructor(api) {
        this._api = api;
    }

    getRooms() {
        return this._api.get('rooms/').then(r => r.data);
    }

    getUserRooms() {
        return this._api.get('rooms/list/all').then(r => r.data);
    }

    getRoom(roomID) {
        return this._api.get(`rooms/${roomID}`).then(r => r.data);
    }

    getRoomEdit(roomID) {
        return this._api.get(`rooms/info/${roomID}`).then(r => r.data);
    }

    getRoomEditRecorded(roomID) {
        return this._api.get(`rooms/${roomID}`).then(r => r.data);
    }

    createRoom(room) {
        return this._api.post('rooms/', room).then(r => r.data);
    }

    editRoom(room, roomID) {
        return this._api.put(`rooms/${roomID}`, room).then(r => r.data);
    }

    activeGoLive(roomID) {
        return this._api.post(`rooms/go/live/${roomID}`).then(r => r.data);
    }

    activeEndLive(roomID) {
        return this._api.post(`rooms/end/live/${roomID}`).then(r => r.data);
    }

    isRoomLive(roomID) {
        return this._api.get(`rooms/live/status/${roomID}`).then(r => r.data);
    }

    getRoomEngagement(roomID) {
        return this._api.get(`rooms/engagement/report/${roomID}`).then(r => r.data);
    }

    getRoomEventLog(roomID) {
        return this._api.get(`rooms/eventlog/report/${roomID}`).then(r => r.data);
    }

    getRoomGraph(roomID, startDate, endDate) {
        return this._api.get(`rooms/graph/report/${roomID}${startDate ? `?fromDate=${startDate}` : ''}${endDate ? `&toDate=${endDate}` : ''}`).then(r => r.data);
    }

    getRoomGeolocalization(roomID, startDate, endDate) {
        return this._api.get(`rooms/geolocalization/report/${roomID}${startDate ? `?fromDate=${startDate}` : ''}${endDate ? `&toDate=${endDate}` : ''}`).then(r => r.data);
    }

    sendMessage(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message`, { text }).then(r => r.data);
    }

    sendQuestion(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message/question`, { text }).then(r => r.data);
    }

    sendReply(roomID, text, originalMessageId) {
        return this._api.post(`/rooms/${roomID}/message/reply`, { text, originalMessageId }).then(r => r.data);
    }

    sendMessageMonitorChat(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message/instructor`, { text }).then(r => r.data);
    }

    sendMessageTeamChat(roomID, text) {
        return this._api.post(`/rooms/${roomID}/message/team`, { text }).then(r => r.data);
    }

    pinMessage(roomID, messageID) {
        return this._api.post(`/rooms/pin/message/${messageID}/${roomID}`).then(r => r.data);
    }

    unpinMessage(roomID, messageID) {
        return this._api.post(`/rooms/unpin/message/${messageID}/${roomID}`).then(r => r.data);
    }

    enterChat(roomID) {
        return this._api.post(`/rooms/${roomID}/enter`).then(r => r.data);
    }

    exitChat(roomID) {
        return this._api.post(`/rooms/${roomID}/exit`).then(r => r.data);
    }

    getVideo(videoID) {
        return this._api.get(`video/${videoID}`).then(r => r.data);
    }

    setChatEnabled(roomId, enabled) {
        return this._api.post(`config/${roomId}/chat/enable`, { enabled });
    }

    getVideoUploadUrl(payload) {
        return this._api.post('rooms/upload/component', payload).then(r => r.data);
    }

    getLiveRecordingUrl(roomId) {
        return this._api.get(`rooms/${roomId}/generate_download_link`).then(r => r.data);
    }

    // teams
    getTeamsEnable(roomID) {
        return this._api.get(`config/${roomID}/teams/enable`).then(r => r.data);
    }

    getTeams(roomID) {
        return this._api.get(`config/${roomID}/teams`).then(r => r.data);
    }

    setTeamsStatus(status, roomID) {
        return this._api.post(`config/${roomID}/teams/enable`, { enabled: status }).then(r => r.data);
    }

    createTeams(roomID, size) {
        return this._api.post(`config/${roomID}/teams/create`, { size }).then(r => r.data);
    }

    // offers
    getOffers(roomID) {
        return this._api.get(`rooms/${roomID}/sale`).then(r => r.data);
    }

    getOfferUrl(roomID) {
        return this._api.get(`rooms/${roomID}/sale/url`).then(r => r.data);
    }

    setOffersStatus(status, roomID) {
        return this._api.put(`rooms/${roomID}/sale/enable`, { enabled: status }).then(r => r.data);
    }

    createOffer(roomID, templateUrl, useSalesforce) {
        return this._api.put(`rooms/${roomID}/sale`, { saleBaseUrl: templateUrl, useSalesForceId: useSalesforce }).then(r => r.data);
    }

}