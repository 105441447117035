import { takeLatest, put, all } from 'redux-saga/effects';
import axios from 'axios';
import api from '../../../services/api';
import Notify from '../../../utils/notification';
import { setListVideo } from './actions';

function getStatus(error) {
  return error.response ? error.response?.data?.error_description : '';
}

function* list() {
  const notify = Notify('Carregando lista de tutoriais de vídeos', true);
  try {
    const list = yield api.videoTutorial.getListVideo();
    yield put(setListVideo(list));
    notify.success('Lista adquirida');
  } catch (error) {
    notify.error(`Falha ao solicitar a lista de tutoriais de vídeo. ${getStatus(error)}`);
  }
}

function* statusVideoTutorial({payload}) {
  try {
    const { id, status} = payload
    yield api.videoTutorial.ChangeStatusListVideoTutorial(id,status)
    Notify.success(status ? "Vídeo ativado com sucesso." : "Vídeo desativado com sucesso.") 
    const list = yield api.videoTutorial.getListVideo();
    yield put(setListVideo(list));
  } catch (error) {
    Notify.error(`Falha ao mudar o estado do vídeo da lista de vídeos.`);
  }
}

function* editVideoTutorial({payload}) {
  try {
    const { id, data} = payload

    yield api.videoTutorial.ChangeEditVideoTutorial(id,data)
    Notify.success("Vídeo editado com sucesso.") 
    const list = yield api.videoTutorial.getListVideo();
    yield put(setListVideo(list));
    
  } catch (error) {
    Notify.error(`Falha ao mudar o estado do vídeo da lista de vídeos. ${getStatus(error)}`);
  }
}

function* createVideoTutorial({payload}) {
  const notify = Notify('Salvando tutorial de vídeo...', true);
  try {
    const { name, uploadedFile, video} = payload
    const infoVideo = yield api.videoTutorial.getInfoVideo({name, image:uploadedFile?.url? uploadedFile?.url: "image" })
    const { upload_token_id, ks,url } = infoVideo
    let  chunkPosition = 1;
    const blocksize = 10240000;
    const chunks = Math.ceil(video?.file?.size / blocksize, blocksize);
    const param = { 'Content-Type': 'multipart/form-data', params: { format: 1 } };
   
    while (chunkPosition <= chunks) {
        const blockStart = (chunkPosition - 1) * blocksize;
        const slice = video.file?.slice(blockStart, chunkPosition * blocksize, video?.file?.type);
        const formData = new FormData();
        formData.append('fileData',slice, video?.name);
        formData.append('ks', ks);
        formData.append('uploadTokenId',upload_token_id);
        formData.append('resume', chunkPosition !== 1);
        formData.append('resumeAt', chunkPosition === 1 ? '0' : '-1');
        formData.append('finalChunk', chunkPosition === chunks);

        yield axios.post(url, formData, param);

        chunkPosition += 1;
  }
    notify.success("Vídeo cadastrado com sucesso.");
    
    const list = yield api.videoTutorial.getListVideo();
    yield put(setListVideo(list));
  } catch (error) {
    notify.error(`Falha ao cadastrar o vídeo. ${getStatus(error)}`);
  }
};

export default all([
  takeLatest('GET_LIST_VIDEO_TUTORIAL', list),
  takeLatest('CHANGE_STATUS_LIST_VIDEO_TUTORIAL', statusVideoTutorial),
  takeLatest('VIDEO_TUTORIAL_CREATE', createVideoTutorial),
  takeLatest('VIDEO_TUTORIAL_EDIT', editVideoTutorial),
]);
