import { userSchemas } from '../../../utils/schemas';

export default class User {
    constructor(api) {
        this._api = api;
    }

    get(username) {
        return this._api.get(`/users/${encodeURIComponent(username)}`).then(r => r.data);
    }

    getById(id) {
        return this._api.get(`/users/by_user_id/${encodeURIComponent(id)}`).then(r => r.data);
    }

    list(keyword) {
        return this._api.get(`/users/${keyword ? `?keyword=${keyword}` : ''}`).then(r => r.data);
    }

    /**
     * @param {{  }} user
     */
    create(user) {
        const { error } = userSchemas.create.validate(user);
        if (error) {
            throw error;
        }
        return this._api.post('/users/', user).then(r => r.data);
    }

    active(username, active) {
        return this._api.post(`/users/${username}/active`, { active });
    }

    edit(username, data) {
        return this._api.post(`/users/${username}`, data).then(r => r.data);
    }

    editCourses(username, courses) {
        return this._api.post(`/users/${username}/courses`, { courses });
    }

    resetPassword(user_id) {
        return this._api.post(`/admin/resetpassword`, { user_id }).then(r => r.data)
    }

    getCourses(userId) {
        return this._api.get(`/users/by_user_id/${userId}/subscriptions`).then(r => r.data);
    }

    subscribreNewCourse(userId, courseId, permission) {
        return this._api.post(`/users/by_user_id/${userId}/subscriptions`, {
            course_id: courseId,
            permission
        }).then(r => r.data);
    }

    editUserCoursePermission(selectedUser, course, permission) {
        return this._api.post(`/users/by_user_id/${selectedUser}/subscriptions/${course}`, {
            permission
        })
    }
}