import React from 'react';

import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/Login';
import User from '../pages/User';
import UsersCoursesList from '../pages/UsersCoursesList';
import Course from '../pages/Course';
import Details from '../pages/Live/Details'
import Live from 'src/pages/Live';
import PresenceReport from 'src/pages/PresenceReport';
import Error404 from '../pages/404/index';
import RecoverPassword from '../pages/RecoverPassword';
import CodeVerification from '../pages/CodeVerification';
import FailedNewPassword from '../pages/RecoverPassword/failed';
import NewPassword from '../pages/RecoverPassword/newPassword';
import SuccessNewPassword from '../pages/RecoverPassword/success';
import CourseVideoList from 'src/pages/CourseVideoList';
import BlockListEmail from 'src/pages/BlockListEmail';
import TutorialVideo from "src/pages/TutorialVideo";
import ManagerSettings from 'src/pages/ManagerSettings';


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/codeVerification" component={CodeVerification} />
      <Route isPrivate path="/courses" component={Course} />
      <Route isPrivate path={`/lives/:id/details`} component={Details} />
      <Route isPrivate path="/home" component={User} />
      <Route path="/recoverPassword/:voucherId?" component={RecoverPassword} />
      <Route isPrivate path="/users" component={User} />
      <Route isPrivate path="/home" component={Live} />
      <Route isPrivate path="/users" component={User} />
      <Route isPrivate path="/courses" component={Course} />
      <Route isPrivate path="/video-tutorial" component={TutorialVideo} />
      <Route isPrivate path="/courseVideos/:courseId" component={CourseVideoList} />
      <Route isPrivate path="/lives" component={Live} />
      <Route isPrivate path="/presence-report" component={PresenceReport} />
      <Route isPrivate path="/users-courses-list" component={UsersCoursesList} />
      <Route isPrivate path="/block-list-email" component={BlockListEmail} />
      <Route isPrivate path="/manager-settings" component={ManagerSettings} />
      <Route path="/failedNewPassword" component={FailedNewPassword} />
      <Route path="/newPassword/:t" component={NewPassword} />
      <Route path="/successNewPassword" component={SuccessNewPassword} />
      <Route notFound component={Error404} />
    </Switch>
  );
}
