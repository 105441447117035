import Joi from '@hapi/joi';

export const userSchemas = {
    create: Joi.object().keys({
        username: Joi.string().email().required(),
        name: Joi.string().min(5).max(200).required(),
        fullname: Joi.string().min(5).max(200).required(),
        password: Joi.string().min(8).required(),
        language: Joi.string().allow(''),
        gender: Joi.string().required(),
        dateOfBirth: Joi.string().required(),
        phone: Joi.number().min(6).required()
    }),
};

export const courseSchemas = {
    create: Joi.object().keys({
        id: Joi.string().min(1).max(200).required(),
        name: Joi.string().min(1).max(200).required(),
        duration: Joi.number().min(0).integer().required(),
        technology: Joi.number().integer().required(),
        extraUser: Joi.boolean().optional(),
        extraUserQty: Joi.number().min(1).max(10).optional().when('extraUser', {is: true, then: Joi.required() })
    })
}

export const videoTutorialSchemas = {
    create: Joi.object().keys({
        id: Joi.string().min(1).max(200).required(),
        name: Joi.string().min(5).max(200).required(),
        image: Joi.string().min(5).max(550).required(),
        category:Joi.string().min(5).max(200).required(),
    }),
};