import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

// eslint-disable-next-line
export default (reducers, middlewares) => {
  const enhancer =
    process.env.NODE_ENV === 'development'
      ? compose(console.tron.createEnhancer(), composeWithDevTools(applyMiddleware(...middlewares)))
      : applyMiddleware(...middlewares);
  return createStore(reducers, enhancer);
};
