export default class RecoverPassword {
    constructor(api) {
        this._api = api;
    }

    async recoverPassword({ email }) {
        await this._api.post('auth/recover', { username: email, useCode: true });
    }

    async verifyRecoverCode(email, code) {
        const { data } = await this._api.post('auth/verify_recover_code', { username: email, recover_code: code });
        return data;
    }

    async changePassword(password, token) {
        await this._api.post('auth/change', { password }, { headers: { Authorization: `Bearer ${token}` } });
    }
}