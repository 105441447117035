import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { VideoPlayer as TokyoPlayer } from 'react-tokyo';
import Moment from 'moment';
import { ContainerPreview, ContainerLogs, Circle } from './styles';
import useRooms from '../../../hooks/useRooms';
import { endLiveRoom, roomIsLive, changeChatEnabled, fetchEditRoom } from '../../../store/modules/rooms/actions';
import Modal from '../../ModalPreview';
import Skeleton from '../../Skeleton';
import { TIMERS } from '../../../utils/constants';
import ToggleSwitch from '../../ToggleSwitch';
import Loading from '../../Loading';
import Tooltip from '../../Tooltip';
import NoVideo from '../../NoVideo';
import RoomSettingUpWarning from '../../RoomSettingUpWarning';

const WebRTCPlayer = React.lazy(() => import('../../WebRTCPlayer'));
const webRtcTechnologyType = 1;

const ReportPreview = ({
    roomEventLog, videoUrl, roomId, startTime, canGoLive, isSettingUp,
}) => {
    const { statusLiveRoomEditSelected, isChatEnabled, technology, socketPath } = useRooms();
    const [showWarn, setShowWarn] = useState(false);

    const dispatch = useDispatch();

    const flowSeverity = Object.freeze({
        0: 'Bom',
        1: 'Aviso',
        2: 'Ruim',
    });
    const flowOptions = Object.freeze({
        primary_stream: 'Fluxo Primário',
        secondary_stream: 'Fluxo Secundário',
    });
    const severityColors = Object.freeze({
        0: '#8dd45c',
        1: '#ffbb33',
        2: '#ff4444',
    });
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [liveTime, setLiveTime] = useState(null);

    const handleEndLive = () => {
        setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen);
    };

    const handleConfirm = () => {
        if (!statusLiveRoomEditSelected?.live) {
            return;
        }
        dispatch(endLiveRoom(roomId));
        setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen);
    };

    useEffect(() => {
        if (startTime) {
            const duration = Moment.duration(Moment.utc(Moment()).diff(Moment.utc(startTime)));
            setLiveTime(Moment.utc(duration.asMilliseconds()));
        }
    }, [startTime]);

    useEffect(() => {
        const timerLive = setInterval(() => {
            if (liveTime && statusLiveRoomEditSelected && statusLiveRoomEditSelected.live) {
                setLiveTime((liveTime) => Moment(liveTime).add(1, 'seconds'));
            }
        }, TIMERS.SECOND);

        return () => clearInterval(timerLive);
        // eslint-disable-next-line
    }, [statusLiveRoomEditSelected])

    useEffect(() => {
        const checkIsLive = setInterval(() => {
            dispatch(roomIsLive(roomId));
        }, TIMERS.CHECK_ROOM_LIVE);

        return () => {
            if (checkIsLive) {
                clearInterval(checkIsLive);
            }
        };
    }, [dispatch, roomId]);

    useEffect(() => {
        const checkChatStatus = setInterval(() => {
            dispatch(fetchEditRoom(roomId));
        }, TIMERS.CHECK_CHAT_STATUS);

        return () => {
            clearInterval(checkChatStatus);
        };
    }, [dispatch, roomId]);

    const handleToggle = () => {
        dispatch(changeChatEnabled(roomId, !isChatEnabled));
    };

    function renderPlayer() {
        if (isSettingUp) {
            return (
                <RoomSettingUpWarning
                    isSettingUp={isSettingUp}
                    title="A sala está sendo criada, aguarde..."
                />
            );
        }

        if (videoUrl && technology !== webRtcTechnologyType) {
            return (
                <TokyoPlayer decodeUrl={videoUrl} />
            );
        }

        if (videoUrl) {
            return (
                <Suspense fallback={<Loading />}>
                    <WebRTCPlayer
                        roomId={roomId}
                        socketPath={socketPath}
                        videoUrl={videoUrl}
                        waitingTransmissionText={'Aguardando Transmissão'}
                        loadingComponent={<Loading />}
                        noVideoComponent={<NoVideo title="OOps!" subtitle="Câmera Desativada" />}
                    />
                </Suspense>
            );
        }
        return null;
    }

    function renderEndLiveButton() {
        return (
            <Tooltip content={'Clique para encerrar a live'}>
                <button
                    disabled={(statusLiveRoomEditSelected && statusLiveRoomEditSelected?.offlive) || (!statusLiveRoomEditSelected?.live && !canGoLive)}
                    onClick={handleEndLive}
                    className={`goLiveButton ${!statusLiveRoomEditSelected?.live && !canGoLive && 'goLiveButtonDisabled'} `}
                    type="submit"
                >
                    End live!
                </button>
            </Tooltip>
        );
    }

    return (
        <ContainerPreview id='section-preview'>
            <Modal isOpen={showWarn} handleModal={() => setShowWarn(false)} title={('Atenção: O relatório de presença só é disponível para conteúdos/vídeos do tipo LIVE').split(':')[0]}>
                <div className="--warn-content">
                    <h5>{'NO_LA live só poderá ser iniciada quando o APRESENTADOR iniciar a sua transmissão.'}</h5>
                    <button type="button" onClick={() => setShowWarn(false)}>OK</button>
                </div>
            </Modal>
            {!roomEventLog ? <Skeleton width="1920" height="420" />
                : (
                    <>
                        <div className="preview">
                            <div className="player">
                                {renderPlayer()}
                            </div>
                            <div className="--video-controls">
                                {(statusLiveRoomEditSelected && statusLiveRoomEditSelected?.live) && renderEndLiveButton()}
                                <div className="chat-enabled">
                                    <span>CHAT {isChatEnabled ? 'HABILITADO' : 'DESABILITADO'}</span>
                                    <ToggleSwitch
                                        isOn={isChatEnabled}
                                        handleToggle={handleToggle}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="log">
                            <div className="header">
                                <picture className="circle"><img alt="" className="inner-circle" /></picture>
                                <h4>{'Ao vivo'}</h4>
                                <p> {liveTime && statusLiveRoomEditSelected && statusLiveRoomEditSelected.live
                                    ? Moment(liveTime).format('HH:mm:ss')
                                    : ''}
                                </p>
                            </div>
                            <div className="log-content" id="log-content">
                                {roomEventLog.map((event, index) => (
                                    <ContainerLogs key={index} severity={severityColors[event.severity]}>
                                        <div className="logs">
                                            <Circle severity={severityColors[event.severity]} />
                                            <p className="flow">
                                                {flowOptions[event.current_stream]}: <span>{flowSeverity[event.severity]}</span>
                                            </p>
                                            <p className="flow-date">
                                                {Moment(event.time).format('kk:mm DD/MM/YYYY')}
                                            </p>
                                        </div>
                                        {event.events.map((subevent, index) => (
                                            <div key={index} className="subevent">
                                                <picture alt="" className="subevent-circle" />
                                                <p>
                                                    {subevent.message}
                                                </p>
                                            </div>
                                        ))}
                                        <div alt="" className="vertical-line" />
                                    </ContainerLogs>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            <Modal title={'Deseja realmente encerrar a live?'} isOpen={isModalConfirmOpen} handleModal={() => setIsModalConfirmOpen((isModalConfirmOpen => !isModalConfirmOpen))}>
                <div className="modal-confirm">
                    <p>{'Esta operação não poderá ser desfeita'}.</p>
                    <div className="confirm-buttons">
                        <button
                            onClick={handleConfirm}
                            type="submit"
                        >{'Confirmar'}
                        </button>
                        <button
                            onClick={() => setIsModalConfirmOpen(isModalConfirmOpen => !isModalConfirmOpen)}
                            className="cancel"
                            type="submit"
                        >{'Cancelar'}
                        </button>
                    </div>
                </div>
            </Modal>
        </ContainerPreview>
    );
};

export default React.memo(ReportPreview);
