export function getPresenceReport(roomId, rowsPerPage, keyword = '') {
  return {
    type: 'GET_PRESENCE_REPORT_REQUEST',
    payload: {
      roomId,
      rowsPerPage,
      keyword
    }
  };
}

export function getPresenceReportSuccess({ nextToken, users }) {
  return {
    type: 'GET_PRESENCE_REPORT_SUCCESS',
    payload: {
      nextToken,
      users
    }
  };
}

export function getPresenceReportFail() {
  return {
    type: 'GET_PRESENCE_REPORT_FAIL',
  };
}

export function getNextPagePresenceReport(roomId, nextToken, rowsPerPage){
  return {
    type: 'PRESENCE_REPORT_NEXT_PAGE',
    payload: {
      roomId,
      nextToken,
      rowsPerPage
    }
  }
}

export function getNextPagePresenceReportSuccess(data) {
  return {
      type: "PRESENCE_REPORT_NEXT_PAGE_SUCCESS",
      payload: {
          nextToken: data.nextToken,
          users: data.users,
      },
  };
}

export function getNextPagePresenceReportFail() {
  return {
      type: "PRESENCE_REPORT_NEXT_PAGE_FAIL",
  };
}