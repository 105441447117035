export function createUser(user) {
  return {
    type: 'USER_CREATE',
    payload: user
  };
}

export function changeActive(username, active) {
  return {
    type: 'USER_CHANGE_ACTIVE',
    payload: {
      username,
      active
    }
  };
}

export function getUsers(keyword) {
  return {
    type: 'USER_GET_LIST',
    payload: keyword
  };
}

export function getUserById(payload) {
  return {
    type: 'GET_USER_BY_ID',
    payload
  };
}

export function userGetCourses() {
  return {
    type: 'USER_GET_COURSES'
  };
}

export function getUserCourses(user) {
  return {
    type: 'GET_USER_COURSES',
    payload: user
  };
}

export function setSelectedUserCourses(userCourses) {
  return {
    type: 'SET_SELECTED_USER_COURSES',
    payload: userCourses
  };
}

export function setSelectedUserListCourse(selectedUser) {
  return {
    type: 'SET_SELECTED_USER',
    payload: selectedUser
  };
}

export function addNewUserCourseSelection(selectedUser, selectedCourse, selectedPermission) {
  return {
    type: 'ADD_NEW_COURSE_TO_USER',
    payload: { selectedUser, selectedCourse, selectedPermission }
  };
}

export function saveUserCoursePermission(selectedUser, course, selectedPermission) {
  return {
    type: 'SAVE_SELECTED_COURSE_PERMISSIONS',
    payload: { selectedUser, course, selectedPermission }
  };
}

export function editUser(username, name, fullname) {
  return {
    type: 'USER_EDIT',
    payload: {
      username,
      name,
      fullname
    }
  };
}

export function editUserCourses(username, courses) {
  return {
    type: 'USER_EDIT_COURSES',
    payload: {
      username,
      courses
    }
  };
}

export function resetUserPassword(user_id) {
  return {
    type: 'USER_RESET_PASSWORD',
    payload: {
      user_id
    }
  };
}

export function showNewUserPassword(user_id, password) {
  return {
    type: 'USER_SHOW_NEW_PASSWORD',
    payload: {
      user_id,
      password
    }
  };
}

export function clearNewUserPassword() {
  return {
    type: 'USER_CLEAR_NEW_PASSWORD',
    payload: {}
  };
}

export function userSearch(search) {
  return {
    type: 'USER_SEARCH',
    payload: search
  };
}

export function selectUserToEdit(userId) {
  return {
    type: 'USER_SELECT_TO_EDIT',
    payload: userId
  };
}

export function setUserEdit(user) {
  return {
    type: 'USER_SET_EDIT',
    payload: user
  };
}

export function setCreateUser(user) {
  return {
    type: 'USER_SET_CREATE',
    payload: user
  };
}

export function setUserList(list) {
  return {
    type: 'USER_SET_LIST',
    payload: list
  };
}

export function userSetCourses(courses) {
  return {
    type: 'USER_SET_COURSES',
    payload: courses
  };
}
