import { all } from 'redux-saga/effects';

import blockListEmail from './blockListEmail/sagas';
import course from './course/sagas';
import live from './live/sagas'
import login from './auth/sagas';
import recoverPassword from './recoverPassword/sagas';
import room from './rooms/saga'
import user from './user/saga';
import presenceReport from './presenceReport/sagas';
import managerSettings from './managerSettings/sagas';
import videoTutorial from './videoTutorial/sagas';

export default function* rootSaga() {
  return yield all([
    blockListEmail,
    course,
    live,
    login,
    recoverPassword,
    room,
    user,
    presenceReport,
    managerSettings,
    videoTutorial
  ])
}
