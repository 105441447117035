export default class VideoTutorial {
    constructor(api) {  
        this._api = api;
    }

    getListVideo() {
        return this._api.get(`/tutorials/admin`).then(r => r.data);
    }
    ChangeStatusListVideoTutorial(videoId, status) {
        return this._api.put(`/tutorials/admin/${videoId}`, { enabled: status }).then(r => r.data);
    }
    ChangeEditVideoTutorial(videoId, data) {
        return this._api.put(`/tutorials/admin/${videoId}`, data).then(r => r.data);
    }
    getInfoVideo({name, image}) {
        return this._api.post(`/tutorials/admin`, { name, image}).then(r => r.data);
    }
}