import React from 'react';
import { ContainerEngagement } from './styles';
import QuestionIcon from '../../../assets/question-2.svg';
import EyeIcon from '../../../assets/eye.svg';
import GroupIcon from '../../../assets/group.svg';
import StreamingIcon from '../../../assets/streaming.svg';
import SpeedIcon from '../../../assets/speedometer.svg';
import Skeleton from '../../Skeleton';
import TooltipEngagement from '../../TooltipEngagement';

const ReportEngagement = ({ roomEngagement }) => {
    return (
        <ContainerEngagement id='container-engagement'>
            {!roomEngagement ? <Skeleton width="1920px" />
                : (
                    <>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={'Total de usuários que estão acessando a transmissão.'}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Eye Icon" src={EyeIcon} className="main-icon" />
                                <h4>{roomEngagement ? roomEngagement.viewers : ''}</h4>
                                <br />
                                <p>{'Usuários ativos'}</p>
                            </div>
                        </div>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={'Porcentagem de usuários com a guia e o som ativados ou em tela cheia.'}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Group Icon" src={GroupIcon} className="main-icon" />
                                <h4>{roomEngagement && roomEngagement.viewers > 0 ? ((roomEngagement.viewers_engagement / roomEngagement.viewers) * 100).toFixed(2) : 0}%</h4>
                                <br />
                                <p>{'Usuários engajados'}</p>
                                <br />
                                <p className="users-ratio">{roomEngagement.viewers_engagement}/{roomEngagement.viewers}</p>
                            </div>
                        </div>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={'Porcentagem de usuários que estão com buffer de mais de 1 segundo.'}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Streaming Icon" src={StreamingIcon} className="main-icon" />
                                <h4>{roomEngagement && roomEngagement.view_buffer_time_ratio > 0 ? (roomEngagement.view_buffer_time_ratio * 100).toFixed(2) : 0}%</h4>
                                <br />
                                <p>{'Classificação do buffer'}</p>
                            </div>
                        </div>
                        <div className="engagement-card">
                            <div className="engagement-card-tooltip-area">
                                <TooltipEngagement
                                    content={'A taxa de bits média relatada pelos players do Kopa.'}
                                >
                                    <img alt="Question Icon" src={QuestionIcon} className="question" />
                                </TooltipEngagement>
                            </div>
                            <div className="engagement-card-info">
                                <img alt="Speed Icon" src={SpeedIcon} className="main-icon" />
                                <h4>{roomEngagement && roomEngagement.avg_view_bitrate > 0 ? (roomEngagement.avg_view_bitrate).toFixed(2) : 0}kbps</h4>
                                <br />
                                <p>{'Taxa de transmissão de bits'}</p>
                            </div>
                        </div>
                    </>
                )}
        </ContainerEngagement>
    );
};

export default React.memo(ReportEngagement);
