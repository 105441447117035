export function createCourse(course) {
  return {
    type: 'COURSE_CREATE',
    payload: course
  };
}

export function getCourse(keyword) {
  return {
    type: 'COURSE_GET_LIST',
    payload: keyword
  };
}

export function getCourses() {
  return {
    type: 'GET_COURSES'
  };
}

export function editCourse(id, name, duration, courseTechnology, interactive, maxClients) {
  return {
    type: 'COURSE_EDIT',
    payload: {
      id,
      name,
      duration,
      courseTechnology, 
      interactive,
      maxClients
    }
  };
}

export function courseSearch(search) {
  return {
    type: 'COURSE_SEARCH',
    payload: search
  };
}

export function selectCourseToEdit(courseId) {
  return {
    type: 'COURSE_SELECT_TO_EDIT',
    payload: courseId
  };
}

export function setCourseEdit(course) {
  return {
    type: 'COURSE_SET_EDIT',
    payload: course
  };
}

export function setCreateCourse(course) {
  return {
    type: 'COURSE_SET_CREATE',
    payload: course
  };
}

export function setCourseList(list) {
  return {
    type: 'COURSE_SET_LIST',
    payload: list
  };
}

export function getCourseVideos(courseId) {
  return {
    type: 'COURSE_VIDEOS_LIST_REQUEST',
    payload: courseId
  }
}

export function getCourseVideosSuccess(videosList) {
  return {
    type: 'COURSE_VIDEOS_LIST_SUCCESS',
    payload: videosList
  }
}


export function getCourseVideosFail() {
  return {
    type: 'COURSE_VIDEOS_LIST_FAIL',
  }
}