export function getBlockListEmails() {
  return {
    type: 'GET_BLOCK_LIST_EMAILS'
  };
};

export function setBlockListEmails(list) {
  return {
    type: 'SET_BLOCK_LIST_EMAILS',
    payload: list
  };
};

export function deleteEmailBlockList(email) {
  return {
    type: 'DELETE_EMAIL_BLOCK_LIST',
    payload: email
  };
};