import React, { useState } from 'react';

import Alert from '../../assets/alert.svg';
import EyeIcon from '../../assets/eye-color.svg';
import EyeHideIcon from '../../assets/eye-hide-color.svg';

import { Container, AInput, ImageIco } from './styles';

const AuthInput = ({
  type,
  autoComplete,
  value,
  onChange,
  onKeyDown,
  passwordIcon,
  onKeyUp,
  placeholder,
  onBlur,
  alertMessage
}) => {
  const [hide, setHide] = useState(true);
  return (
    <Container>
      {passwordIcon && (
        <ImageIco
          onClick={() => setHide(!hide)}
          src={hide ? EyeIcon : EyeHideIcon}
          alt="ico"
        />
      )}
      {alertMessage && (
        <ImageIco
          onClick={() => setHide(!hide)}
          src={Alert}
          alt="ico"
        />
      )}
      <AInput
        placeholder={placeholder}
        type={
          type === 'password' && hide
            ? 'password'
            : type === 'password' && !hide
            ? 'text'
            : type === 'email' && 'email'
        }
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
      />
    </Container>
  );
};

export default AuthInput;
