import { takeLatest, takeLeading, put, all, throttle, delay } from 'redux-saga/effects';

import api from '../../../services/api';
import {
  setCreateUser,
  setUserList,
  getUsers,
  getUserCourses,
  userSetCourses,
  showNewUserPassword,
  setSelectedUserCourses,
  setSelectedUserListCourse
} from './actions';
import Notify from '../../../utils/notification';

function getStatus(error) {
  return error.response ? error.response.status : '';
}

function* create({ payload: user }) {
  const notify = Notify('Solicitando criação de usuário', true);
  try {
    yield api.user.create(user);
    notify.success('Usuário criado');
    yield put(setCreateUser({ name: '', username: '', fullname: '', password: '' }));
  } catch (error) {
    if (error.response.status === 409) {
      notify.error('Já existe um usuário com o email inserido');
    } else {
      notify.error(`Falha ao criar usuário. ${getStatus(error)}`);
    }
  }
}

function* active({ payload: { username, active } }) {
  const notify = Notify('Requisitando alteração de status', true);
  try {
    yield api.user.active(username, active);
    notify.success('Status alterado');
    yield put(getUsers());
  } catch (error) {
    notify.error(`Falha ao alterar status. ${getStatus(error)}`);
  }
}

function* edit({ payload: { username, name, fullname } }) {
  const notify = Notify('Requisitando edição de usuário', true);
  try {
    yield api.user.edit(username, { name, fullname });
    notify.success('Usuário atualizado');
    yield put(getUsers());
  } catch (error) {
    notify.error(`Falha ao editar usuário. ${getStatus(error)}`);
  }
}

function* editCourses({ payload: { username, courses } }) {
  const notify = Notify('Requisitando atualização de cursos', true);
  try {
    const coursesArray = courses.map((course) => ({ courseId: course }));
    yield api.user.editCourses(username, coursesArray);
    notify.success('Cursos do usuário atualizado');
  } catch (error) {
    notify.error(`Falha ao editar cursos do usuário. ${getStatus(error)}`);
  }
}

function* list({ payload }) {
  const notify = Notify('Carregando lista de usuários', true);
  try {
    const search = payload;
    const list = yield api.user.list(search && encodeURIComponent(search));

    yield put(setUserList(list));

    if (list.length === 0) {
      return notify.error('Usuário não encontrado');
    }

    notify.success('Lista adquirida');
  } catch (error) {
    notify.error(`Falha ao solicitar lista de usuários ${getStatus(error)}`);
  }
}

function* courses() {
  const notify = Notify('Carregando cursos', true);
  try {
    const courses = yield api.course.courses();
    yield put(userSetCourses(courses));
    notify.success('Cursos carregados');
  } catch (error) {
    notify.error(`Falha ao solicitar cursos ${getStatus(error)}`);
  }
}

function* getSelectedUserCourses({ payload }) {
  const notify = Notify('Carregando', true);
  try {
    const userCourses = yield api.user.getCourses(payload);
    yield put(setSelectedUserCourses(userCourses));
    notify.success('Cursos carregados');
  } catch (error) {
    console.log('get users error:', error);
    notify.error(`Falha ao obter cursos ${getStatus(error)}`);
  }
}

function* addNewUserCourseSelection({ payload }) {
  const notify = Notify('Carregando cursos', true);
  const { selectedUser, selectedCourse, selectedPermission } = payload;
  try {
    yield api.user.subscribreNewCourse(selectedUser.user_id, selectedCourse, selectedPermission);
    yield delay(1000);
    yield put(getUserCourses(selectedUser.user_id));
    notify.success('Matrícula efetuada com sucesso');
  } catch (error) {
    console.log('Error ao obter curso', error);
    notify.error(`Falha ao obter cursos ${getStatus(error)}`);
  }
}

function* saveUserCoursePermission({ payload }) {
  const notify = Notify('Salvando modificações no curso');
  const { selectedUser, course, selectedPermission } = payload;
  try {
    yield api.user.editUserCoursePermission(
      selectedUser.user_id,
      course.course_id,
      selectedPermission
    );
    yield delay(1000);
    yield put(getUserCourses(selectedUser.user_id));
  } catch (error) {
    console.log('Erro permissão', error);
    notify.error(`Falha ao editar permissão de curso ${getStatus(error)}`);
  }
}

function* resetUserPassword({ payload: { user_id } }) {
  const notify = Notify('Trocando senha do usuário', true);
  try {
    const { password } = yield api.user.resetPassword(user_id);
    notify.success('Senha trocada com sucesso');
    yield put(showNewUserPassword(user_id, password));
  } catch (error) {
    notify.error(`Falha ao trocar a senha do usuário ${user_id}`);
  }
}

function* getUserById({ payload }) {
  const notify = Notify('Pegando dados do usuário', true);
  try {
    const response = yield api.user.getById(payload);
    notify.success('Dados recebidos com sucesso');
    yield put(setSelectedUserListCourse(response));
  } catch (error) {
    notify.error(`Falha ao pegar dados do usuário ${payload}`);
  }
}

export default all([
  takeLeading('USER_CREATE', create),
  takeLeading('USER_CHANGE_ACTIVE', active),
  takeLeading('USER_EDIT', edit),
  takeLeading('USER_EDIT_COURSES', editCourses),
  takeLeading('USER_GET_COURSES', courses),
  takeLeading('USER_RESET_PASSWORD', resetUserPassword),
  takeLatest('USER_GET_LIST', list),
  takeLatest('GET_USER_COURSES', getSelectedUserCourses),
  takeLatest('ADD_NEW_COURSE_TO_USER', addNewUserCourseSelection),
  takeLatest('SAVE_SELECTED_COURSE_PERMISSIONS', saveUserCoursePermission),
  takeLatest('GET_USER_BY_ID', getUserById),
  throttle(1000, 'USER_SEARCH', list)
]);
