/* eslint-disable indent */
import actions from '../../../utils/actions';

const DEFAULT_STATE = {
    channels: [],
    channelsListForSelectSidebar: [],
    selectedChannel: {
        id: null,
        name: null,
    },
    selectedRoom: null,
    selectedEditRoom: null,
    statusLiveRoomEditSelected: null,
    roomMessages: [],
    roomPinnedMessages: [],
    isChatEnabled: true,
    questionMessages: [],
    instructorRoomMessages: [],
    teamMessages: [],
    loading: true,
    chatTabs: null,
    chatUserType: { instructor: false, organizer: true },
    isEnabledTeamChat: false,
    roomEngagement: null,
    roomEventLog: null,
    roomGraph: null,
    roomGeolocalization: null,
    saleEnabled: false,
    videoToUpload: null,
    progress: 0,
    isUploadingInBackground: [],
    selectedChannelRooms: [],
    hasGamification: false,
    hasExtraUsers: false,
    technology: null,
    videoUrl: null,
    socketPath: null,
};
export default function roomReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
        case actions.SET_CHANNELS_SIDEBAR:
            return {
                ...state,
                channelsListForSelectSidebar: payload,
                selectedChannel: JSON.parse(localStorage.getItem('@selectedChannel')) || payload[0],
            };
        case actions.SET_CHANNEL_SELECTED:
            return { ...state, selectedChannel: payload };
        case 'ROOM_REQUEST':
            return { ...state, loading: true };
        case 'USER_ROOMS_REQUEST':
            return { ...state, loading: true };
        case 'ROOMS_SUCCESS':
            return { ...state, rooms: payload, loading: false };
        case 'ROOM_SUCCESS':
            return {
                ...state,
                selectedRoom: payload,
                loading: false,
                isChatEnabled: payload.chatEnabled,
                saleEnabled: payload.saleEnabled,
                roomPinnedMessages: payload.pinnedMessages,
            };
        case actions.ROOM_EDIT_SELECT_SUCCESS:
            return {
                ...state,
                selectedEditRoom: payload,
                isChatEnabled: payload.chatEnabled,
                loading: false,
                technology: payload.technology,
                videoUrl: payload.videoUrl,
                socketPath: payload.socketPath,
            };
        case actions.ROOM_FAIL:
            return { ...state, loading: false };
        case actions.ROOM_EDIT_SELECT:
            return { ...state, loading: true };
        case 'ROOM_CLEAR_SELECTED':
            return {
                ...state,
                selectedRoom: null,
                selectedEditRoom: null,
                statusLiveRoomEditSelected: null,
                roomMessages: [],
                roomPinnedMessages: [],
                questionMessages: [],
                instructorRoomMessages: [],
                teamMessages: [],
                chatUserType: DEFAULT_STATE.chatUserType,
                roomEngagement: null,
                roomEventLog: null,
                roomGraph: null,
                roomGeolocalization: null,
            };
        case actions.ROOM_CLEAR_EDIT_SELECTED:
            return {
                ...state,
                selectedEditRoom: null,
            };
        case actions.ROOM_SET_STATUS_ROOM:
            return {
                ...state,
                statusLiveRoomEditSelected: payload,
            };
        case 'ROOM_SET_MESSAGES':
            return {
                ...state,
                roomMessages: payload.roomMessages,
                questionMessages: payload.questionMessages,
                instructorRoomMessages: payload.instructorRoomMessages,
                teamMessages: payload.teamMessages,
            };
        case 'ROOM_SET_CHAT_TABS':
            return { ...state, chatTabs: payload };
        case actions.ROOM_CREATE:
            return { ...state, loading: true };
        case actions.ROOM_SET_CHAT_USER_TYPE:
            return { ...state, chatUserType: payload.chatUserType };

        case actions.ROOM_CHAT_SET_ENABLE_TEAM:
            return {
                ...state,
                isEnabledTeamChat: payload.isEnabledTeamChat,
                teamMessages: !payload.isEnabledTeamChat ? [] : state.teamMessages,
            };

        case actions.ROOM_CHAT_SET_CHAT_ENABLE_ROOM:
            return {
                ...state,
                isChatEnabled: payload.isChatEnabled,
                roomMessages: !payload.isChatEnabled ? [] : state.roomMessages,
                questionMessages: !payload.isChatEnabled ? [] : state.questionMessages,
                instructorRoomMessages: !payload.isChatEnabled ? [] : state.instructorRoomMessages,
                teamMessages: !payload.isChatEnabled ? [] : state.teamMessages,
            };

        case actions.ROOM_CHAT_SET_TEAM_INFORMATION:
            return {
                ...state,
                teamInformation: payload.teamInformation,
                teamMessages: (payload.teamInformation.teamId !== state.teamInformation.teamId) ? [] : state.teamMessages,
            };
        case actions.ROOM_SET_ENGAGEMENT:
            return {
                ...state,
                roomEngagement: payload,
            };

        case actions.GET_COURSE_REQUEST:
            return { ...state, loading: true };
        case actions.GET_COURSE_SUCCESS:
            return { ...state, loading: false, selectedChannelRooms: payload };
        case actions.GET_COURSE_FAILURE:
            return { ...state, loading: false, selectedChannelRooms: [] };
        case actions.SET_HASGAMIFICATION:
            return { ...state, hasGamification: payload };
        case actions.SET_HASEXTRAUSERS:
            return { ...state, hasExtraUsers: payload };
        case actions.ROOM_SET_EVENTLOG:
            return { ...state, roomEventLog: payload };
        case actions.ROOM_SET_GRAPH:
            return { ...state, roomGraph: payload };
        case actions.ROOM_SET_GEOLOCALIZATION:
            return { ...state, roomGeolocalization: payload };
        case actions.ROOM_SHOW_QRCODE:
            return { ...state, saleEnabled: payload };
        case actions.ROOM_SET_PIN_MESSAGE:
            return { ...state, roomPinnedMessages: payload };
        case actions.ROOM_SET_CHAT_ENABLED:
            return { ...state, isChatEnabled: payload.chatEnabled };
        case actions.ROOM_SET_VIDEO_UPLOAD:
            return { ...state, videoToUpload: payload };
        case actions.ROOM_UPLOAD_VIDEO_UPDATE_PROGRESS:
            return { ...state, progress: payload };
        case actions.ROOM_UPLOAD_IN_BACKGROUND:
            return { ...state, isUploadingInBackground: payload };
        case 'CLEAR_ALL':
        case 'LOGOUT':
            return DEFAULT_STATE;
        default:
            return state;
    }
}
