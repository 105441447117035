import styled from 'styled-components';

export const DropDownContainer = styled.div`
  position: relative;
  z-index: 1;
  
  .dropdown {
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    background-color: #282B3A;
    min-width: 180px;
    left: -180px;
    top: 10px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    text-align: left;
    padding: 10px;

    h3 {
      text-align: center;
    }

    #h4-time{
        color: white;
    }

    .type-buttons {
        display: flex;
        margin-bottom: 5px;

        button {
            font-size: 18px;
            width: 200px;
        }
        
        .selected{
            color: #ff9953;
        }
    }
    
    #preset, #date-range {
        background-color: inherit !important;
        

        span {
            font-weight: 400;
            font-size: 14px;
        }
    }

    .preset {
        ul {
            columns: 2; 
            cursor: pointer;
            list-style-type: none;

            li {
                display: flex;
                flex-direction: row;
                text-align: left;
                padding: 5px;
                color: white;
            }

            img {
                margin-left: 5px;
            }
        }
    }

    .range {
        text-align: center;
        
        h4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-itens: center;
            margin: 0 20%;
            text-align: left;
        }

        span {
            height: 40px;
        }

        .timeFrom input{
            background-color: white;
            padding: 5px;
        }

        .timeTo input{
            background-color: white;
            padding: 5px;
        }

        .submitButton {
            background-color: #ff9953;
            width: 80px;
            margin: 20px 0 12px;
            
            p {
                text-align: center;
                margin: 0;
                color: white;
            }
        }

        .react-datepicker__day--outside-month {
            color: transparent;
            pointer-events: none;
        }
        
    }
  }

  .dropdown a {
    color: white;
    padding: 12px 16px;
    display: block;
    border-top: 1px solid #2f2e2e;
    
    div { 
      float: left;
      height: 20px;
      width: 20px;
      margin: 0 5px;
    }
  }

  .dropdown h3 {
    padding: 12px 16px;
    margin: 0 auto;
    
  }
`;