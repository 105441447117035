import { takeLatest, takeLeading, put, all, throttle, select } from 'redux-saga/effects';
import api from '../../../services/api';
import Notify from '../../../utils/notification';
import { setLives } from './actions';

function getStatus(error) {
  return error.response ? error.response?.data?.error_description : '';
}

function* list() {
  const notify = Notify('Carregando lista de lives', true);
  try {
    const list = yield api.live.lives();
    yield put(setLives(list));
    notify.success('Lista adquirida');
  } catch (error) {
    notify.error(`Falha ao solicitar lista de lives ${getStatus(error)}`);
  }
}

export default all([
  takeLatest('GET_LIVES', list),
]);
