import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, DialogActions, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { format, isFuture } from 'date-fns';

import Modal from "src/components/Modal";
import { userSchemas } from "src/utils/schemas";
import Notify from "src/utils/notification";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { resetUserPassword } from "src/store/modules/user/actions";

export default function UserModal({ user, open, title, onSave, onClose }) {
  const dispatch = useDispatch();

  const isNew = !user?.user_id;

  const passwordInfo = useSelector(state => state.user.passwordInfo, shallowEqual);

  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    name: user ? user.name : '',
    fullname: user ? user.fullname : '',
    active: user ? user.active : false,
    username: user ? user.username : '',
    phone: user ? user.phone : '',
    gender: user ? user.gender : '',
    language: user ? user.language : '',
    dateOfBirth: user ? user.dateOfBirth : format(new Date(), 'yyyy-MM-dd'),
    password: ''
  });

  useEffect(() => {
    if (passwordInfo) {
      onClose();
    }
  }, [passwordInfo]);

  const handleSave = () => {
    const userData = {
      username: values?.username,
      name: values?.name,
      fullname: values?.fullname,
      password: values?.password,
      language: values?.language,
      gender: values?.gender,
      dateOfBirth: values?.dateOfBirth,
      phone: Number(values?.phone)
    }
  
    if (isNew) {
      const { error: validation } = userSchemas.create.validate(userData);
      if (validation) {
        return Notify.error(validation.message);
      }
      
      if (isFuture(new Date(dateOfBirth))) {
        return Notify.error('A Data de nascimento não pode ser maior que a data atual!');
      }
    }

    onSave({...values, user_id: user?.user_id});
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Modal open={open} title={title} onClose={onClose}>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { width: '100%' } }}
        autoComplete="off"
        noValidate
      >
        <div>
          <TextField sx={{ m: 1 }} id="name" label="Nome" type="text" value={values?.name} onChange={handleChange('name')} fullWidth />
          <TextField sx={{ m: 1 }} id="fullname" label="Nome Completo" type="text" value={values?.fullname} onChange={handleChange('fullname')} fullWidth />
          {isNew && (
            <TextField
              sx={{ m: 1, width: '100%' }}
              id="username"
              label="E-mail"
              type="email"
              value={values?.username}
              onChange={handleChange('username')}
              fullWidth
            />
          )}
          {!isNew && (
            <FormControl sx={{ m: 1 }} fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={values?.active}
                label="Status"
                onChange={handleChange('active')}
              >
                <MenuItem value={true}>Ativo</MenuItem>
                <MenuItem value={false}>Inativo</MenuItem>
              </Select>
            </FormControl>
          )}
          {isNew && (
            <>
              <TextField
                sx={{ m: 1, width: '100%' }}
                id="phone"
                label="Telefone"
                value={values?.phone}
                onChange={handleChange('phone')}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                fullWidth
              />
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="language-select-label">Idioma</InputLabel>
                <Select
                  labelId="language-select-label"
                  id="language-select"
                  value={values?.language}
                  label="Idioma"
                  onChange={handleChange('language')}
                >
                  <MenuItem value={'pt-BR'}>Português</MenuItem>
                  <MenuItem value={'en-US'}>Inglês</MenuItem>
                  <MenuItem value={'es-ES'}>Espanhol</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="gender-select-label">Gênero</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={values?.gender}
                  label="Gênero"
                  onChange={handleChange('gender')}
                >
                  <MenuItem value={'M'}>Masculino</MenuItem>
                  <MenuItem value={'F'}>Feminino</MenuItem>
                  <MenuItem value={'X'}>Não Informar</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                <InputLabel shrink id="dateOfBirth">Data de Nascimento</InputLabel>
                <TextField
                    name="dateOfBirth"
                    type="date"
                    defaultValue={values?.dateOfBirth}
                    onChange={handleChange('dateOfBirth')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: format(new Date(), 'yyyy-MM-dd')
                    }}
                />
              </FormControl>
            </>
          )}
          {!isNew && (
            <Button
              variant="outlined"
              color="info"
              onClick={() => dispatch(resetUserPassword(user?.user_id))}
              fullWidth
            >
              Trocar Senha do Usuário
            </Button>
          )}
          {isNew && (
            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Senha (min. 8 caracteres)
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={event => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Senha (min. 8 caracteres)"
              />
            </FormControl>
          )}
        </div>
      </Box>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={() => handleSave()}>Salvar</Button>
      </DialogActions>
    </Modal>
  );
}