import React, { useRef, useState, useEffect } from 'react';

//recharts
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

//material
import { 
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';

import { ContainerGraph } from './styles';
import Moment from 'moment';
import TimeFilter from '../TimeFilter';
import ArrowDown from '../../../assets/arrow-down.svg';
import Skeleton from '../../Skeleton';

import { graphOptionsTranslate } from 'src/utils/functions';

const defaultGraphMockData = [
    {
        name: "No Content",
        a: 100,
        b: 100,
    },
];

const ReportGraph = ({ roomGraph, roomId }) => {
    const wrapperRef = useRef(null);
    const [graphLine1Selected, setGraphLine1Selected] = useState('');
    const [graphLine2Selected, setGraphLine2Selected] = useState('');
    const [graphOptions, setGraphOptions] = useState([]);
    const [graphData, setGraphData] = useState(null);
    const [filterTimeGraph, setFilterTimeGraph] = useState(false);
    const [presetTimeFilter, setPresetTimeFilter] = useState(0);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFilterTimeGraph(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(wrapperRef);

    const handleGraphLine1 = (e) => {
        setGraphLine1Selected(e.target.value);
    };

    const handleGraphLine2 = (e) => {
        setGraphLine2Selected(e.target.value);
    };

    useEffect(() => {
        if (roomGraph) {
            const graphData = [];
            if (graphLine1Selected || graphLine2Selected) {
                for (let index = 0; index < roomGraph[0].coordinates.length; index++) {
                    const line1 = roomGraph.findIndex(e => e.id === graphLine1Selected);
                    const line2 = roomGraph.findIndex(e => e.id === graphLine2Selected);

                    graphData.push({
                        time: Moment.utc(roomGraph[roomGraph[line1] ? line1 : line2].coordinates[index][0]).local().format('DD/MM/YYYY HH:mm'),
                        id: roomGraph[line1] ? Number(roomGraph[line1].coordinates[index][1]) : '',
                        id2: roomGraph[line2] ? Number(roomGraph[line2].coordinates[index][1]) : '',
                    });
                }
            }
            setGraphData(graphData);
        }
    }, [graphLine1Selected, graphLine2Selected, roomGraph]);

    useEffect(() => {
        if (roomGraph) {
            setGraphOptions(roomGraph.map(option => ({ value: option.id, label: option.id.replace(/_/g, '').toUpperCase() })));
        }
        // eslint-disable-next-line
    }, [roomGraph])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <ContainerGraph isVisible={roomGraph}>
            {!roomGraph ? <div className="skeleton-container"><Skeleton width="1920" height="305" /></div>
                : (
                    <>
                        <div className="header">
                            <div className="filter-graph">
                                <Box sx={{ minWidth: 240 }}>
                                    <FormControl fullWidth color = 'grey'>
                                        <InputLabel id="filter-graph1">Selecione o primeiro filtro</InputLabel>
                                        <Select
                                            labelId="filter-graph1"
                                            id="filter-1"
                                            value={graphLine1Selected}
                                            label="Selecione o primeiro filtro"
                                            onChange={handleGraphLine1}
                                            MenuProps={MenuProps}
                                        >
                                            {graphOptions.map((option) => {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>{graphOptionsTranslate(option.value)}</MenuItem>
                                                )
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <p id='vs'> VS </p>
                                <Box sx={{ minWidth: 240 }}>
                                    <FormControl fullWidth color = 'grey'>
                                        <InputLabel id="filter-graph2">Selecione o segundo filtro</InputLabel>
                                        <Select
                                            labelId="filter-graph2"
                                            id="filter-2"
                                            value={graphLine2Selected || ''}
                                            label="Selecione o segundo filtro"
                                            onChange={handleGraphLine2}
                                            MenuProps={MenuProps}
                                        >
                                            {graphOptions.map((option) => {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>{graphOptionsTranslate(option.value)}</MenuItem>
                                                )
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="button-filter" ref={wrapperRef}>
                                <button
                                    type="button"
                                    className="dropdown-button"
                                    onClick={() => (filterTimeGraph ? setFilterTimeGraph(false) : setFilterTimeGraph(true))}
                                >
                                    <p >{'Selecione o tempo'}</p><img id="arrow-down" src={ArrowDown} alt="arrow down" />
                                </button>
                                {filterTimeGraph
                                    ? <TimeFilter
                                        roomId={roomId}
                                        presetTimeFilter={presetTimeFilter}
                                        setPresetTimeFilter={setPresetTimeFilter}
                                    />
                                    : ''}
                            </div>
                        </div>

                        <div className="graph-content">
                            {graphData && graphData.length > 0
                                ? (
                                    <ResponsiveContainer width="97%" height={260}>
                                        <LineChart
                                            data={graphData}
                                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                        >
                                            <XAxis dataKey="time" />
                                            <YAxis />
                                            <YAxis yAxisId="right" orientation="right" />
                                            <Tooltip />
                                            {graphLine1Selected ? <Line type="monotone" dot={false} dataKey="id" stroke="#f99652" activeDot={{ r: 8 }} /> : ''}
                                            {graphLine2Selected ? <Line type="monotone" dot={false} dataKey="id2" stroke="#8884d8" activeDot={{ r: 8 }} yAxisId="right" /> : ''}
                                        </LineChart>
                                    </ResponsiveContainer>
                                )
                                : (
                                    <ResponsiveContainer width="97%" height={260}>
                                        <LineChart
                                            data={defaultGraphMockData}
                                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                        >
                                            <XAxis dataKey="time" />
                                            <YAxis />
                                            <YAxis yAxisId="right" orientation="right" />
                                            <Line type="monotone" dataKey="a" stroke="#f99652" />
                                            <Line type="monotone" dataKey="b" stroke="#8884d8" yAxisId="right" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                )}
                        </div>
                    </>
                )}
        </ContainerGraph>
    );
};

export default React.memo(ReportGraph);
