export function getLives(){
  return {
    type: 'GET_LIVES'
  };
};

export function setLives(list) {
  return {
    type: 'SET_LIVES',
    payload: list
  };
};