export function getRooms() {
    return {
      type: 'GET_ROOMS'
    };
  }

export function setRoomList(list) {
    return {
      type: 'SET_ROOM_LIST',
      payload: list
    };
};  

export function getLogs() {
    return {
      type: 'GET_LOGS'
    };
  }

export function setLogs(log){
  return {
    type: 'SET_LOGS',
    payload: log
  }
}

export function getConfigs() {
    return {
      type: 'GET_CONFIGS'
    };
  }

export function setConfigs(config) {
  return {
    type: 'SET_CONFIGS',
    payload: config
  }
}

export function editConfig(config) {
  return {
    type: 'EDIT_CONFIGS',
    payload: config
  };
}

export function deleteRoom(id) {
  return {
    type: 'DELETE_ROOM',
    payload: id
  };
};


