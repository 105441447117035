import styled from 'styled-components';
import { TableContainer } from '@mui/material';


export const TableContainers = styled(TableContainer)`
    min-width: 800;

    .img-thumbnail {
        background: rgb(0, 0, 0, 0.2);
        width: 120px;
        height: 120px;
        object-fit: cover;
    }

    .textStatus {
        text-transform: capitalize;
    }
`;

