import { takeLatest, put, all } from 'redux-saga/effects';
import api from '../../../services/api';
import Notify from '../../../utils/notification';
import { getBlockListEmails, setBlockListEmails } from './actions';

function getStatus(error) {
  return error.response ? error.response?.data?.error_description : '';
}

function* list() {
  const notify = Notify('Carregando lista de Emails bloqueados', true);
  try {
    const list = yield api.blockListEmail.blockListEmail();
    yield put(setBlockListEmails(list));
    notify.success('Lista adquirida');
  } catch (error) {
    notify.error(`Falha ao solicitar lista de Emails bloqueados ${getStatus(error)}`);
  }
}

function* deleteEmailBlockList({payload}) {
  const notify = Notify('Excluindo email da lista de bloqueio...', true);
  try {
    const email = payload;
    yield api.blockListEmail.deleteEmailBlockList(email);
    notify.success('Email excluído da lista de bloqueio com sucesso.');
    yield put(getBlockListEmails());
  } catch (error) {
    notify.error(`Falha ao excluir email da lista de bloqueio. ${getStatus(error)}`);
  }
}

export default all([
  takeLatest('GET_BLOCK_LIST_EMAILS', list),
  takeLatest('DELETE_EMAIL_BLOCK_LIST', deleteEmailBlockList),
]);
