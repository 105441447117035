import { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

export default function DropdownTableMenu({options = []}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  function handleClickAction(action){
    if(action){
        action()
    }
    setIsOpen(false);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: options[0]?.width, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        {
            options.map(option => (
                <MenuItem key={option?.label} sx={{ color: 'text.secondary' }} onClick={() => handleClickAction(option?.action)}>
                    {option?.icon && (
                        <ListItemIcon>
                            <Icon icon={option?.icon} width={24} height={24} />
                        </ListItemIcon>
                    )}
                    <ListItemText primary={option?.label} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            ))
        }

      </Menu>
    </>
  );
}

