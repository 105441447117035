import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Divider,
  DialogContentText,
  DialogActions,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Modal from '../../components/Modal';
import { UserListHead, UserListToolbar, UserMoreMenu, UserModal } from '../../components/_dashboard/user';
// actions
import { changeActive, clearNewUserPassword, createUser, editUser, getUsers, setSelectedUserListCourse, userSearch } from '../../store/modules/user/actions'
import { mockImgAvatar } from 'src/utils/mockImages';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'fullname', label: 'Nome Completo', alignRight: false },
  { id: 'username', label: 'E-mail', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { list: USERLIST } = useSelector(state => state.user);
  const passwordInfo = useSelector(state => state.user.passwordInfo, shallowEqual);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userTimeout, setUserTimeout] = useState(null);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleSaveUser = (userData) => {
    const {
      name, fullname, username, active, password,
      language, gender, dateOfBirth
    } = userData;

    const phone = Number(userData.phone)

    if (!selectedUser) {
      dispatch(createUser({
        name, fullname, username, password,
        language, gender, dateOfBirth, phone
      }));
    } else {
      dispatch(editUser(username, name, fullname));
      dispatch(changeActive(username, active));
    }

    handleCloseModal();
  }

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedUser(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleListCoursesUser = (row) => {
    dispatch(setSelectedUserListCourse(row));
    history.push('/users-courses-list');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    clearTimeout(userTimeout);
    setUserTimeout(setTimeout(dispatch, 500, userSearch(event.target.value)));
    setFilterName(event.target.value);
  };

  const getUserName = (user_id) => {
    return USERLIST?.find(({ user_id: id }) => id === user_id)?.name;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy));

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuários
          </Typography>
          <Button
            variant="contained"
            component="button"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setIsOpenModal(true)}
          >
            Novo Usuário
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            caller={'user'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { user_id: id, username, name, fullname, active } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="checkbox">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={mockImgAvatar()} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{fullname}</TableCell>
                          <TableCell align="left">{username}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={active ? 'success' : 'error'}
                            >
                              {active ? 'Ativo' : 'Inativo'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu
                              onClick={() => handleListCoursesUser(row)}
                              onEdit={() => handleEditUser(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {isOpenModal && (
        <UserModal
          user={selectedUser}
          open={isOpenModal}
          title={`${selectedUser ? 'Editar' : 'Criar'} Usuário`}
          onSave={userData => handleSaveUser(userData)}
          onClose={() => handleCloseModal()}
        />
      )}

      {passwordInfo !== undefined && (
        <Modal
          open={true}
          title="Senha alterada com sucesso!"
          onClose={() => dispatch(clearNewUserPassword())}
        >
          <DialogContentText>
            A senha do usuário <strong>{getUserName(passwordInfo?.user_id)}</strong> foi alterada para <strong>{passwordInfo?.password}</strong>
          </DialogContentText>
          <Divider />
          <DialogActions>
            <Button
              color="primary"
              variant='contained'
              onClick={() => dispatch(clearNewUserPassword())}
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Page>
  );
}
