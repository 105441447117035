const DEFAULT_STATE = {
  users: [],
  loading: true,
  totalPages: null,
  nextReportLoading: false,
  nextToken: null,
  fromSearch: false,
  verified: false,
};

export default function reducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case "GET_PRESENCE_REPORT_REQUEST":
      return { ...state, loading: true };
    case "GET_PRESENCE_REPORT_SUCCESS":
      return {
        ...state,
        loading: false,
        users: payload.users,
        nextToken: payload.nextToken,
        fromSearch: payload.fromSearch,
      };
    case "GET_PRESENCE_REPORT_FAIL":
      return {
        ...state,
        loading: false,
        nextReportLoading: false,
        users: [],
      };
    case "PRESENCE_REPORT_NEXT_PAGE":
      return {
        ...state,
        loading: true,
      };
    case "PRESENCE_REPORT_NEXT_PAGE_SUCCESS":
      return {
        ...state,
        users: [...state.users, ...payload.users],
        nextToken: payload.nextToken,
        nextReportLoading: false,
      };
    case "PRESENCE_REPORT_NEXT_PAGE_FAIL":
      return {
        ...state,
      };
    default:
      return state;
  }
}
