const DEFAULT_STATE = {
  list: []
};

export default function reducer (state = DEFAULT_STATE, {type, payload}) {
  switch (type){
    case 'SET_LIST_VIDEO_TUTORIAL':
      return { ...state, list: payload };

    default:
      return state;
  };
}
