import React from 'react';
import { Button } from '@mui/material';

class _Notify {
  constructor(key) {
    this.key = key;
  }

  success = (...args) => {
    _Notify.success(...args);
    window.closeSnackbar(this.key);
  };

  error = (...args) => {
    _Notify.error(...args);
    window.closeSnackbar(this.key);
  };

  warn = (...args) => {
    _Notify.warn(...args);
    window.closeSnackbar(this.key);
  };

  /**
   * @returns {_Notify} notify class
   */
  static _create(message, variant, persist = false, autoHideDuration = 2000) {
    const action = (key) => <Button onClick={() => window.closeSnackbar(key)}>Fechar</Button>;
    const key = window.enqueueSnackbar(message, { variant, persist, autoHideDuration, action });
    return new _Notify(key);
  }

  static success(message, persist, time = 3000) {
    return _Notify._create(message, 'success', persist, time);
  }

  static error(message, persist, time = 5000) {
    return _Notify._create(message, 'error', persist, time);
  }

  static common(message, persist, time = 2000) {
    return _Notify._create(message, undefined, persist, time);
  }

  static warn(message, persist, time = 3000) {
    return _Notify._create(message, 'warning', persist, time);
  }
}

const Notify = _Notify.common;
Notify.success = _Notify.success;
Notify.error = _Notify.error;
Notify.warn = _Notify.warn;

export default Notify;
