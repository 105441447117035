import { useSelector, useDispatch } from "react-redux";
import { useBreadcrumbs } from "../../hooks/useBreadcrumbs";
import { useLocation, useHistory } from "react-router-dom";
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import exportFill from "@iconify/icons-eva/arrow-circle-down-outline";
import refreshFill from "@iconify/icons-eva/refresh-fill";
import { useDebounce } from "use-debounce/lib";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import ListToolbar from "../../components/ListToolbar";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  CourseListHead,
  CourseModal,
} from "../../components/_dashboard/course";

// actions
import {
  getPresenceReport,
  getNextPagePresenceReport,
} from "../../store/modules/presenceReport/actions";
import api from "src/services/api";
import Notify from "src/utils/notification";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "fullName", label: "Nome completo", alignRight: false },
  {
    id: "percentageViewTime",
    label: "Porcentagem de Visualização",
    alignRight: false,
  },
  {
    id: "sumViewTime",
    label: "Soma de Tempo de visualização",
    alignRight: false,
  },
  { id: "username", label: "Username", alignRight: false },
];

export default function PresenceReport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { nextToken, users } = useSelector((state) => state.presenceReport);

  const [page] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage] = useState(10);
  const [minCharactersSearch] = useState(3);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { breadcrumbs, setItems } = useBreadcrumbs();
  const [keyword, setKeyword] = useState("");
  const [selectedChannel, setSelectedChannel] = useState();

  const [debouncedKeyword] = useDebounce(keyword, 1000);

  useEffect(() => {
    const breadcrumbsItems = [
      { to: `/courses`, label: "Cursos" },
      { to: `/courseVideos/${selectedChannel}`, label: "Videos" },
      { to: `/presence-report?course=${selectedCourse}?channelId=${selectedChannel}`, label: "Relatório de Presença" },
    ];
    setItems(breadcrumbsItems);
  }, [setItems, selectedCourse, selectedChannel]);

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getPresenceReport(selectedCourse, rowsPerPage));
    }
  }, [selectedCourse]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedCourse(null);
  };

  const handleFilterByName = (event) => {
    let value = event.target.value;

    setFilterName(value);
    setKeyword(value);

    if(event.nativeEvent.inputType === "deleteContentBackward" && value === "" ) {
      dispatch(getPresenceReport(selectedCourse, rowsPerPage, value));
    }

    if (debouncedKeyword.length < minCharactersSearch) {
      Notify.warn("Insira no mínimo 3 caracteres");
      return;
    } else {
      dispatch(getPresenceReport(selectedCourse, rowsPerPage, debouncedKeyword));
    }
    
  };

  async function handleExport() {
    Notify("Carregando");

    try {
      const response = await api.exportPresenceReport(selectedCourse);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${"Relatório de Presença".replace(/ /g, "_")}_${new Date()
          .toLocaleString()
          .replace(/\//g, "-")
          .replace(/ /g, "_")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      Notify.error("Falha ao baixar relatório");
      // setDownloading(false);
    }
  }

  const handleRefreshReportList = () => {
    setKeyword("");
    dispatch(getPresenceReport(selectedCourse, 10));
  };

  const handleNextPage = () => {
    dispatch(getNextPagePresenceReport(selectedCourse, nextToken, rowsPerPage));
  };

  function renderViewTime(timeInSeconds) {
    const sec_num = parseInt(timeInSeconds, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;


  const isUserNotFound = users.length === 0;

  useEffect(() => {
    const queryCourseId = location?.search?.split("=")[1]?.split("?")[0];
    const queryChannelId = location?.search?.split("=")[2]

    if(!queryChannelId || !queryChannelId) { 
      history.push("/")
    }
    setSelectedCourse(queryCourseId);
    setSelectedChannel(queryChannelId);
  }, [location]);

  useEffect(() => {
    if (debouncedKeyword.length < minCharactersSearch) return;
    dispatch(getPresenceReport(selectedCourse, rowsPerPage, debouncedKeyword));
  }, [debouncedKeyword])

  return (
    <Page title="Courses">
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Relatório de Presença
          </Typography>
          <Button
            variant="text"
            component="button"
            startIcon={<Icon icon={refreshFill} />}
            onClick={() => handleRefreshReportList()}
          >
            Recarregar Lista
          </Button>
          {users.length > 0 && (
            <Button
              variant="contained"
              component="button"
              startIcon={<Icon icon={exportFill} />}
              onClick={() => handleExport()}
            >
              Download relatório
            </Button>
          )}
        </Stack>
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            caller={"Buscar participante..."}
          />
          
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CourseListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {users.map((row) => {
                    const {
                      fullName,
                      percentageViewTime,
                      sumViewTime,
                      username,
                    } = row;
                    return (
                      <TableRow
                        hover
                        key={username}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{fullName}</TableCell>
                        <TableCell align="left">
                          {(percentageViewTime * 100).toFixed(1) || 0} %
                        </TableCell>
                        <TableCell align="left">
                          {renderViewTime(sumViewTime && sumViewTime)}
                        </TableCell>
                        <TableCell align="left">{username}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          {nextToken !== null && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              mt={5}
            >
              <Button
                variant="contained"
                component="button"
                style={{
                  margin: "auto",
                  alignSelf: "center",
                }}
                onClick={() => handleNextPage()}
              >
                Carregar mais
              </Button>
            </Stack>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            paddingX={10}
            mb={2}
            mt={2}
          >
            <span>{users?.length || 0} resultados</span>
          </Stack>
        </Card>
      </Container>
      {isOpenModal && (
        <CourseModal
          course={selectedCourse}
          open={isOpenModal}
          title={`${selectedCourse ? "Editar" : "Criar"} Curso`}
          maxWidth="lg"
          onSave={(courseData) => handleSaveCourse(courseData)}
          onClose={() => handleCloseModal()}
        />
      )}
    </Page>
  );
}
