import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Image.propTypes = {
    sx: PropTypes.object
};

export default function Image({image, width, height}) {
    const img = '/static/thumbnail.png';
    return <Box component="img"
    className='img-thumbnail'
    src={image}
    onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = img;
    }}
    sx={{ mx: 2, width: {width}, height: {height} }} />
}

