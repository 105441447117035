import React, { useRef, useState, useEffect } from "react";
import Modal from "src/components/Modal";
import RecordedVideo from "src/components/Recorded";
import { useDispatch } from "react-redux";
import Notify from "src/utils/notification";
import fileSize from "filesize";
import { uniqueId } from "lodash";
import Axios from "axios";
import api from "src/services/api";
import AvatarEditor from "react-avatar-editor";
import Upload from "../../KopaUpload/Upload";
import { MdError, MdOutlineImageNotSupported } from "react-icons/md";
import { TextField, Divider, Button } from "@mui/material";
import { createVideo, editVideo } from "src/store/modules/videoTutorial/actions";
import {
  TutorialVideoForm,
  TutorialVideoFormUploadArea,
  TutorialFormUploadDropzone,
  TutorialVideoFormUploadHeader,
  DialogAction,
} from "./styles";
import {
  CropContainer,
  UploadContainer,
  FileListContainer,
  FileListContent,
  Preview,
} from "../../KopaUpload/styles";

export default function VideoTutorialModal({ open, editVideoTutorialsData, openEdit, title, onClose, maxWidth = "lg" }) {
  const setEditorRef = useRef();
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState([]);
  const [upImg, setUpImg] = useState();
  const [name, setName] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});
  const [dataImageVideoEdit, setDataImageVideoEdit] = useState("");
  const [video, setVideo] = useState({});

  useEffect(() => {
    if (openEdit && editVideoTutorialsData) {
      setName(editVideoTutorialsData.name);
      setDataImageVideoEdit(editVideoTutorialsData.image);
    }
  }, [editVideoTutorialsData]);

  function handlerDeleteImage() {
    setDataImageVideoEdit("");
    setUploadedFile({});
  }

  async function handleData() {
    if (openEdit) {
      let data = { name, image: uploadedFile.url ? uploadedFile.url : dataImageVideoEdit };
      dispatch(editVideo(editVideoTutorialsData.id, data));
      onClose();
    } else {
      let data = { name, uploadedFile, video };

      if (name.length === 0) {
        return Notify.error(`O título não pode ser nulo.`);
      }

      if (!video) {
        return Notify.error(`O vídeo não pode ser nulo.`);
      }

      if (name && video) {
        dispatch(createVideo(data));
        onClose();
      }
    }
  }

  function updateFileProgress(data, progress) {
    const uploadingFile = data;
    uploadingFile.progress = progress.progress;

    setUploadedFile(uploadingFile);
  }

  function updateFileUrl(data, url) {
    const uploadingFile = data;
    uploadingFile.url = url;
    uploadingFile.uploaded = true;
    setUploadedFile(uploadingFile);
  }

  const processUpload = async (uploadedFile) => {
    const data = new FormData();
    const baseUrl = `/config/thumbnail/upload/${uploadedFile.name}`;

    setIsUploading([...isUploading, uploadedFile.name]);

    try {
      const response = await api._api.post(baseUrl);
      const { fields } = response.data.uploadObject;
      Object.keys(fields).forEach((key) => {
        data.append(key, fields[key]);
      });
      data.append("file", uploadedFile.file);

      await Axios.post(`${response.data.uploadObject.url}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (e) => {
          const progress = Number(Math.round((e.loaded * 100) / e.total));
          updateFileProgress(uploadedFile, { progress });
        },
      });

      setIsUploading((prev) =>
        prev.filter((item) => item !== uploadedFile.name)
      );
      const urlFile = response.data.imageUrl;
      updateFileUrl(uploadedFile, urlFile);
    } catch (err) {
      const error = uploadedFile;
      error.error = true;
      setIsUploading((prev) =>
        prev.filter((item) => item !== uploadedFile.name)
      );
      setUploadedFile([error]);
    }
  };

  const handleUpload = ([file]) => {
    setUploadedFile(file);
    const image = new Image();
    image.addEventListener("load", () => {
      if (image.width < 300 || image.height < 300) {
        Notify.warn("O tamanho minimo da imagem");
        return;
      }

      const newUploadedFiles = {
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: fileSize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
      };
      setUploadedFile(newUploadedFiles);
      processUpload(newUploadedFiles);
    });
    image.src = URL.createObjectURL(file);
  };

  const onCroppedEnd = () => {
    let canvasScaled;

    if (setEditorRef.current) {
      canvasScaled = setEditorRef.current.getImageScaledToCanvas();
    } else {
      return;
    }

    setUpImg(null);
    canvasScaled.toBlob((file) => {
      handleUpload([new File([file], `${uniqueId()}.png`, file)]);
    }, "image/png");
  };

  const KopaSingleImgUpload = () => {
    return (
      <UploadContainer>
        {upImg && (
          <Modal
            isOpen={!!upImg}
            width={600}
            handleModal={() => setUpImg(null)}
            title="Meu perfil"
            bodyWidth={100}
          >
            <CropContainer>
              <div className="crop-component">
                <AvatarEditor
                  image={upImg}
                  width={300}
                  height={300}
                  scale={1}
                  rotate={0}
                  disableHiDPIScaling
                  ref={setEditorRef}
                />
                <button
                  type="submit"
                  onClick={() => onCroppedEnd()}
                  disabled={!upImg}
                >
                  CROP_IMAGE
                </button>
              </div>
            </CropContainer>
          </Modal>
        )}

        {!uploadedFile.id && !dataImageVideoEdit && (
          <Upload onUpload={handleUpload} message="Upload de imagem" />
        )}

        {(uploadedFile.name || dataImageVideoEdit) && (
          <FileListContainer>
            <FileListContent className="file-list-content">
              <Preview src={dataImageVideoEdit || uploadedFile.preview} />

              <div className="contain-infos-image">
                {dataImageVideoEdit ? null : (
                  <div>
                    <p>
                      <b>Nome:</b> {uploadedFile.name}
                    </p>
                    <p>
                      <b>Tamanho:</b> {uploadedFile.readableSize}
                    </p>
                  </div>
                )}

                <button
                  className="btn-delete"
                  type="button"
                  onClick={() => handlerDeleteImage()}
                >
                  <MdOutlineImageNotSupported className="icon" /> Excluir Imagem
                </button>
              </div>

              {((!uploadedFile.uploaded && !uploadedFile.error) ||
                uploadedFile.error) && (
                <div className="upload-info">
                  {uploadedFile.error && <MdError size={24} color="#e57878" />}
                </div>
              )}
            </FileListContent>
          </FileListContainer>
        )}
      </UploadContainer>
    );
  };

  return (
    <Modal open={open} title={title} onClose={onClose} maxWidth={maxWidth}>
      <TutorialVideoForm>
        <TextField
          required
          id="title"
          className="input-title"
          value={name}
          label="Título"
          type="text"
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />

        <TutorialVideoFormUploadArea>
          <TutorialVideoFormUploadHeader>
            <h4>Upload de Imagem <span> Opcional</span></h4>
          </TutorialVideoFormUploadHeader>
          <TutorialFormUploadDropzone>
            <KopaSingleImgUpload />
          </TutorialFormUploadDropzone>
          {!openEdit && (
            <RecordedVideo
              handleFileCallback={(e) => setVideo(e)}
              message="Clique aqui para fazer ulpoad do video"
            />
          )}
        </TutorialVideoFormUploadArea>
      </TutorialVideoForm>

      <Divider />

      <DialogAction>
        <Button variant="contained" onClick={() => handleData()}>Salvar</Button>
      </DialogAction>
    </Modal>
  );
}
