import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs';
import useRooms from '../../../hooks/useRooms';
import {
    fetchEditRoom, getRoomEngagement, getRoomEventLog, getRoomGeolocalization, getRoomGraph, roomIsLive, clearEditSelected,
} from '../../../store/modules/rooms/actions';
import Page from 'src/components/Page';
// material
import { Container } from '@mui/material';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContainerDetails, Header, HeaderButtonsContainer } from './styles';
import ReportEngagement from '../../../components/Reports/Engagement';
import ReportPreview from '../../../components/Reports/Preview';
import ReportGraph from '../../../components/Reports/Graph';
import ReportGeolocalization from '../../../components/Reports/Geolocalization';
import Loading from '../../../components/Loading';
import { TIMERS } from '../../../utils/constants';

export default function RoomDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { breadcrumbs, setItems } = useBreadcrumbs();
    const {
        selectedEditRoom,
        roomEngagement,
        roomEventLog,
        roomGraph,
        roomGeolocalization,
    } = useRooms();

    useEffect(() => {
        const breadcrumbsItems = [
            { to: `/lives`, label: 'LIVES' },
            { to: '#', label: 'DASHBOARD' }
        ];
        setItems(breadcrumbsItems);
    }, [setItems]);

    useEffect(() => {
        dispatch(fetchEditRoom(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (id) {
            dispatch(roomIsLive(id));
            dispatch(fetchEditRoom(id));
            dispatch(getRoomEngagement(id));
            dispatch(getRoomEventLog(id));
            dispatch(getRoomGraph(id));
            dispatch(getRoomGeolocalization(id));
        }

        return () => dispatch(clearEditSelected());
    }, [dispatch, id]);

    useEffect(() => {
        const checkRoomEngagement = setInterval(() => {
            dispatch(getRoomEngagement(id));
            dispatch(getRoomEventLog(id));
        }, TIMERS.REFRESH_ENGAGEMENT);

        return () => {
            clearInterval(checkRoomEngagement);
        };
    }, [dispatch, id]);

    return (
        <Page title="Dashboard Live">
            <Container>
                <Breadcrumbs
                    items={breadcrumbs}
                />
                <ContainerDetails className="deatails container">
                    {selectedEditRoom?.id
                        ? (
                            <>
                                <Header className="header">
                                    <h4>{selectedEditRoom ? selectedEditRoom.name : ''}</h4>
                                </Header>

                                <ReportEngagement roomEngagement={roomEngagement} />

                                <ReportPreview
                                    roomEventLog={roomEventLog}
                                    videoUrl={selectedEditRoom ? selectedEditRoom.videoUrl : ''}
                                    roomId={id}
                                    startTime={selectedEditRoom ? selectedEditRoom.currentBroadcastStartTime : ''}
                                    canGoLive={selectedEditRoom && selectedEditRoom?.canGoLive}
                                    isSettingUp={selectedEditRoom && selectedEditRoom?.isSettingUp}
                                />
                                <ReportGraph
                                    roomGraph={roomGraph}
                                    roomId={id}
                                />
                                <ReportGeolocalization roomGeolocalization={roomGeolocalization} />
                            </>
                        ) : <Loading />}
                </ContainerDetails>
            </Container>
        </Page>
    );
}
