import React from 'react';
import iconLoading from '../../assets/spinner.svg';
import { Container } from './styles';

// import './Loading.scss';

const Loading = () => (
    <Container className="loading">
        <img src={iconLoading} alt="loading" />
    </Container>
);

export default Loading;
