import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { ContainerGeolocalization, LineTable } from './styles';
import GeoData from '../../../assets/geochart.world.json';
import GeoChart from '../../GeoChart';
import Skeleton from '../../Skeleton';

const ReportGeolocalization = ({ roomGeolocalization }) => {
    return (
        <ContainerGeolocalization>
            { !roomGeolocalization ? <Skeleton width="1920" height="600" />
                : (
                    <>
                        <div className="header">
                            <p>{'Geolocalização'}</p>
                        </div>
                        <div className="geo-content">
                            { roomGeolocalization
                    && <GeoChart data={GeoData} property={roomGeolocalization} />}
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <tr>
                                            <th>#</th>
                                            <th align="center">{'NOME'}</th>
                                            <th align="left">{'USUÁRIOS ATIVOS'}</th>
                                            <th align="left">{'DIS. DE USUÁRIOS'}</th>
                                            <th align="left">{'CLAS. DO BUFFER'}</th>
                                        </tr>
                                    </TableHead>
                                    <TableBody>
                                        {roomGeolocalization
                                            ? roomGeolocalization.data.map((country, index) => (
                                                <LineTable key={index} index={index}>
                                                    <td align="center">{index}</td>
                                                    <td align="center">{country.country}</td>
                                                    <td align="left">{country.view_unique_audience}</td>
                                                    <td align="left">{`${(country.view_unique_audience / roomGeolocalization.totalUsers) * 100}%`}</td>
                                                    <td align="left">{`${country.view_buffer_time_ratio}%`}</td>
                                                </LineTable>
                                            )) : ''}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                )}
        </ContainerGeolocalization>
    );
};

export default React.memo(ReportGeolocalization);
