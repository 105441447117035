import styled from 'styled-components';

export const RecordedContainer = styled.div`  
  display: flex;
  width: 100%;
  h4 {
    margin-bottom: 10px;
  }

  .upload {
    border: 1px solid rgb(0, 0, 0, 0.15);
    border-radius: 8px; 
  }

  .VideoInput_video {
    border: 1px solid rgb(0, 0, 0, 0.15);
    border-radius: 8px; 
  }

  .dropzone {
    height: 200px;
    
    p {
      height: 100%;
    }
  }

  .btn-delete {
    width: 180px;
    padding: 6px 10px;
    background: #ea0430;
    color: #fff;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    border-radius: 5px;

    :hover {
        background: #a90020;
    }

    .icon {
        margin-bottom: -3px;
        font-size: 18px;
    }
  }

  .thumb-span {
    color: #989797;
    margin: 0;
    font-size: 10px;
    font-weight: bold;

    span {
      color: #ff6487;
      font-size: 10px;
      font-weight: bold;
    }
  }

  .contain-update-video {
    width: 100%;
    
    video {
      cursor: pointer;
      background: #ababab;
      border: none;
    }
  }
`;

export const VideoPlayer = styled.div`
  display: inline-block;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 40%;
  

  @media(min-width: 650px) {
    height: 50%;
    margin-bottom: 10px;
    padding: 0 20px 0 20px;
    
    main {
      height: 250px !important;
    }
    
  }

  @media(max-width: 650px) and (orientation: landscape) {
    height: 100%;
  }
`;

export const UrlContainer = styled.div`

`;

export const UploadButton = styled.button`
  margin: 30px auto;
  display:flex;
  justify-content:center;
  width:100%;
  height: 40px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.21px;
  line-height: 3;
  border: solid 1px;

  &:enabled {
    border-color: #ff468a !important;
    border-width: 1px;
    color:#fff;
    color: #ff448b;
    transition: all .2s ease-in-out;
  }
  
  &:hover {
    transform: scale(1.1);
  }  
`;
