
import React, { useEffect } from 'react';
import {
    Container,
    Content,
    CloseButton,
    ContentTitle,
    Title,
    ContentBody,
} from './styles';

import closeIcon from '../../assets/close-icon.svg';

export default function ModalPreview({
    isOpen, handleModal, children, width, title = '', featuredTitle = '', bodyWidth = null,
}) {
    useEffect(() => {
        const disableBodyOverflow = () => {
            if (isOpen) {
                document.body.style.overflowY = 'hidden';
            } else {
                document.body.style.overflowY = 'visible';
            }
        };
        disableBodyOverflow();
    }, [isOpen]);

    return (
        isOpen && (
            <Container>
                <Content width={width}>
                    <ContentTitle>
                        <Title>
                            <p id='title-modal'>{`${title}`}</p>
                            <p className="featured">
                                {`${featuredTitle}`}
                            </p>
                        </Title>
                        <CloseButton className="--pulse" type="button" onClick={handleModal}>
                            <img src={closeIcon} alt="Fechar modal" />
                        </CloseButton>
                    </ContentTitle>
                    <ContentBody id={'modal-body'} bodyWidth={bodyWidth}>
                        {children}
                    </ContentBody>
                </Content>
            </Container>
        )
    );
}
