import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
// Material UI
import {
    Card,
    Stack,
    Typography,
  } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// components
import Scrollbar from '../../Scrollbar';
// actions
import { getLogs } from '../../../store/modules/managerSettings/actions';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
// ---------------------------------------------------------------------------------------------------------------
const StyledTextArea = styled(TextareaAutosize)(() => ({
  width:'97%',margin: '1em', minHeight: '90%'
}));


export default function ManagerLogs(){
   const dispatch = useDispatch();

   const { log: LOGTEXT } = useSelector(state => state.managerSettings);
 
   const {breadcrumbs, setItems } = useBreadcrumbs();
 
   useEffect(() => {
     const breadcrumbsItems = [
       { to: `/manager-settings`, label: "Manager" },
     ];
     setItems(breadcrumbsItems);
   }, [setItems]);
 
   useEffect(() => {
     dispatch(getLogs());
   }, []);

 
    return (
        <>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={2}>
                <Typography variant="h4" gutterBottom>
                  Log <Icon 
                        icon={refreshFill}
                        onClick={() => dispatch(getLogs())} />
                </Typography>
              </Stack>
              <Card>
                <Scrollbar>
                  <StyledTextArea
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows"
                    defaultValue={LOGTEXT}
                    style={{ width:'97%',margin: '1em', minHeight: '90%' }}
                    disabled
                  />
                </Scrollbar> 
              </Card>
            </>
    )
}
