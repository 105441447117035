import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 110vh;
  background: #282b3a;
  flex-direction: column;
  display: flex;
  position: relative;

  @media(min-width: 340px) {
    height: 100vh;
  }
  @media (max-width: 900px) and (orientation: landscape) {
    height: auto; 
    padding:  50px 0;
  }

  &:after {
    content: '';
    width: 100%;
    height: 7px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;

    background: linear-gradient(90deg, #FF438D 0%, #FFAC47 100%);
  }
`;

export const Header = styled.div`
  position: fixed;
  width: 100%;
  padding: 21px 15px 20px 105px;
  transition: all 0.5s ease-in-out;
  top: 0;
  z-index: 5;
  background: #282b3a;
  @media (max-width: 800px) {
      padding: 0;
      display: flex;
      flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 6%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;

    width: 50%;
  }

  input {
    background: #fff;
    border: 1;
    border-radius: 8px;
    height: 44px;
    padding: 0 15px;
    color: #000;
    margin: 0 0 10px;
    border-color: #fff #949494 #949494 #fff;

    &::placeholder {
      color: #949494;
    }
  }

  button {
    padding: 15px 0;
    border: 0;
    border-radius: 8px;
    color:#fff;
    background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;

    &:hover {
        background: linear-gradient(90deg,  rgba(255,66,141,0.8) 0%, rgba(255,174,70,0.8) 100%);
    }
  }

  @media(max-height: 850px) { 
      justify-content: start;  
  }
`;
