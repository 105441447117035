import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import KopaLogo from '../../assets/kopa-logo.svg';
import { loginRequest } from '../../store/modules/auth/actions';
import { Container, Form, ErrorMessage, ErrorContaineir, InputArea, LinkButton } from './styles';
import attention from '../../assets/attention.svg';
import AuthInput from '../../components/AuthInput';

function selector({ login }) {
  return { error: login.error };
}

export default function Login() {
  const dispatch = useDispatch();
  const { error } = useSelector(selector, shallowEqual);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [messageFailEmail, setMessageFailEmail] = useState(false);
  const loading = useSelector((state) => state.login.loading);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleLogin() {
    if (password !== '' || email !== '') {
      dispatch(loginRequest(email, password));
    }
  }

  function checkEmptyFields() {
    if (!email || !password || !isEmailValid) {
      return true;
    }
    if (email && password && isEmailValid) {
      return false;
    }

    return true;
  }

  function validateEmail(email) {
    const emailRegex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
    if (emailRegex.test(email)) {
      setIsEmailValid(true);
      return true;
    }
    if (email.length > 0) {
      setIsEmailValid(false);
      return false;
    }
    return false;
  }

  function keyHandler(evt) {
    if (evt.keyCode === 13 && !loading) {
      handleLogin();
    }
  }

  function checkInvalidEmail() {
    if (!isEmailValid) {
      setMessageFailEmail(true);
    } else {
      setMessageFailEmail(false);
    }
  }

  function handleChange(e) {
    setEmail(e);
    setIsEmailValid(validateEmail(e));
    if (validateEmail(e)) {
      setMessageFailEmail(false);
    }
  }

  return (
    <Container>
      <img className="logo" src={KopaLogo} alt="KopaLogo" />
      <Form>
        <div className="loginInputLabel">
          <span>E-MAIL</span>
        </div>
        <InputArea>
          <AuthInput
            type="email"
            value={email}
            autoComplete={window.chrome ? 'no' : 'off'}
            onChange={(e) => handleChange(e.target.value)}
            onKeyUp={keyHandler}
            onBlur={() => checkInvalidEmail()}
            alertMessage={messageFailEmail && messageFailEmail}
          />
          {messageFailEmail && (
            <div className="emailMessageError">
              <p>EMAIL INVÁLIDO</p>
            </div>
          )}
          <div className="loginInputLabel">
            <span>SENHA</span>
          </div>
          <AuthInput
            passwordIcon
            type="password"
            autoComplete={window.chrome ? 'no' : 'off'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={keyHandler}
          />
        </InputArea>
        <LinkButton id="recover" to="/recoverPassword">
          ESQUECEU SUA SENHA?
        </LinkButton>
        {error && (
          <ErrorContaineir>
            <img src={attention} alt="att" />
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorContaineir>
        )}
        <button
          id={checkEmptyFields() ? 'submitButtonDisabled' : 'submitButton'}
          disabled={loading || checkEmptyFields() || !isEmailValid}
          type="submit"
          onClick={handleLogin}
        >
          {loading ? `CARREGANDO...` : 'LOGIN'}
        </button>
      </Form>
    </Container>
  );
}
