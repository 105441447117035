import React from 'react';
import KopaLogo from '../../assets/kopa-logo.svg';
import caution from '../../assets/alert.svg';
import { Container, MessageCointaine, LinkButton } from './styles';

export default function SuccessRecover() {
    return (
        <Container>
            <img
                src={KopaLogo}
                alt="KopaLogo"
                className="logo"
            />
            <MessageCointaine>
                <img
                    className="correct-image"
                    src={caution}
                    alt="KopaLogo"
                />
                <h1 className="alert-title">Ops!</h1>
                <h3 className="alert-subtitle">O link não está mais disponível.</h3>
                <LinkButton to="/">
                    <button
                        type="submit"
                    > Voltar
                    </button>
                </LinkButton>
            </MessageCointaine>

        </Container>
    );
}
