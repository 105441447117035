import createSagaMiddleware from 'redux-saga';

import { createBrowserHistory } from 'history';

import createStore from './createStore';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMonitor =
  process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const browserHistory = createBrowserHistory();

const middlewares = [sagaMiddleware];

const store = createStore(rootReducer(browserHistory), middlewares);

sagaMiddleware.run(rootSaga);

export { store };
