import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { verifyRecoverCodeRequest, changePasswordRequest, clearErrors } from '../../store/modules/recoverPassword/actions';
import KopaLogo from '../../assets/kopa-logo.svg';
import attention from '../../assets/alert.svg';
import CodeInput from './CodeInput';
import {
    Container,
    ErrorContaineir,
    Form,
    InputArea,
    InstructionsArea,
    ResendArea,
} from './styles';

function selector({ recoverPassword }) {
    return { error: recoverPassword.error };
}

export default function CodeVerification() {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [email, setEmail] = useState('');
    const [messageFailCode, setMessageFailCode] = useState(false);
    const [timer, setTimer] = useState('00:00');

    const dispatch = useDispatch();
    const { error } = useSelector(selector, shallowEqual);
    const loading = useSelector((state) => state.recoverPassword.loading);
    const history = useHistory();
    const intervalRef = useRef(null);
    const inputRefA = useRef();
    const inputRefB = useRef();
    const inputRefC = useRef();
    const inputRefD = useRef();
    const inputRefE = useRef();
    const inputRefF = useRef();

    const INPUTREFS = {
        0: inputRefA,
        1: inputRefB,
        2: inputRefC,
        3: inputRefD,
        4: inputRefE,
        5: inputRefF,
    };

    useEffect(() => {
        dispatch(clearErrors());
    }, [dispatch]);

    useEffect(() => {
        function checkEmailInfo() {
            if (history.location.state) {
                setEmail(history.location.state);
                return;
            }
            history.push('/recoverPassword');
        }

        return checkEmailInfo();
    }, [history]);

    useEffect(() => {
        setMessageFailCode(error);
    }, [error, messageFailCode]);

    function getTimeRemaining(totalTime) {
        const total = Date.parse(totalTime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor(total / 1000 / 60);
        return { total, minutes, seconds };
    }

    function startTimer(deadline) {
        const { total, minutes, seconds } = getTimeRemaining(deadline);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : `0${minutes}:`) + (seconds > 9 ? seconds : `0${seconds}`),
            );
        } else {
            clearInterval(intervalRef.current);
        }
    }

    function clearTimer(totalTime) {
        setTimer('01:00');
        if (intervalRef.current) clearInterval(intervalRef.current);
        const _id = setInterval(() => {
            startTimer(totalTime);
        }, 1000);
        intervalRef.current = _id;
    }

    function getDeadlineTime() {
        const deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    useEffect(() => {
        clearTimer(getDeadlineTime());
        return () => { if (intervalRef.current) clearInterval(intervalRef.current); };
        // eslint-disable-next-line 
    }, []);

    function startNewTimer() {
        if (intervalRef.current) clearInterval(intervalRef.current);
        clearTimer(getDeadlineTime());
    }

    function handleCodeVerification() {
        dispatch(verifyRecoverCodeRequest(email, otp.join('')));
    }

    async function handleNewPassword() {
        if (email !== '') {
            dispatch(changePasswordRequest(email));
            startNewTimer();
        } else {
            history.push('/recoverPassword');
        }
    }

    function handleRef(index) {
        return INPUTREFS[index];
    }

    function checkEmptyFields() {
        const otpValues = otp.join('');
        if (otpValues.length === 6) {
            return false;
        }
        return true;
    }

    function handleChange(elem, index) {
        if (index < 5 && INPUTREFS[index]?.current?.value !== '') {
            INPUTREFS[index + 1].current.select();
        }
        setOtp([...otp.map((digit, idx) => ((idx === index) ? elem.target.value : digit))]);
    }

    function keyHandler(evt, index) {
        INPUTREFS[index].current.value = '';

        if (index === 0 && evt.keyCode === 8) {
            setTimeout(() => { INPUTREFS[0].current.value = ''; }, 100);
        }

        if (evt.keyCode === 8 && !loading && index > 0) {
            INPUTREFS[index].current.value = '';
            setTimeout(() => INPUTREFS[index - 1].current.focus(), 100);
            setOtp([...otp.map((digit, idx) => ((idx === index) ? INPUTREFS[index].current.value : digit))]);
            return;
        }
        if (checkEmptyFields()) {
            return;
        }
        if (evt.keyCode === 13 && !loading) {
            handleCodeVerification();
        }
    }

    return (
        <Container>
            <img className="logo" src={KopaLogo} alt="KopaLogo" />
            <Form>
                <InstructionsArea>
                    <h1>CÓDIGO DE VERIFICAÇÃO</h1>
                    <p>
                        Encaminhamos um código de verificação para o e-mail: <br /> <strong> {email}</strong>
                    </p>
                </InstructionsArea>
                <div className="codeInputLabel">
                    <span>CÓDIGO DE VERIFICAÇÃO</span>
                </div>
                <InputArea>
                    {
                        otp.map((digit, index) => (
                            <CodeInput
                                id={`inputRef${index}`}
                                ref={handleRef(index)}
                                key={index}
                                name={`otp${index}`}
                                type="text"
                                value={digit}
                                autoComplete={window.chrome ? 'no' : 'off'}
                                onChange={(e) => handleChange(e, index)}
                                alertMessage={messageFailCode && messageFailCode}
                                onKeyDown={e => keyHandler(e, index)}
                            />
                        ))
                    }
                </InputArea>
                {
                    error === 'NÚMERO MÁXIMO DE TENTATIVAS ALCANÇADOS' || error === 'CÓDIGO EXPIRADO'
                        ? history.push('/recoverPassword') : error && (
                            <ErrorContaineir>
                                <img src={attention} alt="ico" />
                                <span>{error}</span>
                            </ErrorContaineir>
                        )
                }
                {
                    timer === '00:00' ? (
                        <ResendArea>
                            <p>
                                NÃO RECEBEU SEU CÓDIGO?
                                {' '}
                                <strong
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleNewPassword()}
                                >
                                    REENVIAR CÓDIGO
                                </strong>
                            </p>
                        </ResendArea>

                    ) : (
                        <ResendArea>
                            <p>{timer} PARA REENVIAR O CÓDIGO</p>
                        </ResendArea>
                    )
                }
                <button
                    id={
                        checkEmptyFields()
                            ? 'submitButtonDisabled'
                            : 'submitButton'
                    }
                    disabled={
                        loading || checkEmptyFields()
                    }
                    type="submit"
                    onClick={() => handleCodeVerification()}
                >
                    {loading ? `CARREGANDO...` : 'VERIFICAR O CÓDIGO'}
                </button>

            </Form>
        </Container>
    );
}
