import styled from 'styled-components';

export const ContainerGeolocalization = styled.section`
  margin: 4%;
  position: relative;

  .header {
      background-color: #D1D0D0;
      justify-content: center;
      min-height: 55px;
      flex-wrap: wrap;
      h4 {
          line-height: 3;
          margin: 0 5px;
      }

      p {
          line-height: 3;
      }
  }

  .geo-content {
      background-color: #EFEFEF;
      width: 100%;
      margin-top: 2rem;

      svg {
          width: 100%;
          height: 500px;
          background-color: #EFEFEF;
      }

      thead {
          border: 0;
          background-color: #D1D0D0;
          margin: 10px;
          color: #282B3A;
      }

      th {
          height: 38px;
          background-color: #D1D0D0;
      }

      td {
          background-color: #EFEFEF;
      }
  }

  .button-filter {
      margin: auto;
      position: absolute;
      right: 0;
      top: 10px;

      .dropdown-button {
          color: white;
          margin-right: 10px;
          background-color: #302d2d;
          height: 38px;

          span {
              font-size: 14px;
          }
      }

      p {
          width: inherit;
          text-align: center;
          margin: 0 15px;
          font-weight: 400;
          font-size: 16px; 
          line-height: 2.5 !important;
      }

      img {
          margin: auto;
          padding-right: 5px;
      }

      .selected {
          span {
              color: #ff8072;
          }
      }
  }
`;

export const LineTable = styled.tr`
    background-color: ${props => (props.index % 2 === 0 ? '#212032' : '#242339')};
    color: #282B3A;
    font-weight: 600;
    height: 38px;
`;
