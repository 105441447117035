import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    /* padding: 1rem; */
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;    
    flex-wrap: wrap;

    .row {
        display: grid;    
        grid-template-columns: 1fr 1fr auto ;
        grid-gap: 10px;    
        @media (max-width: 1030px){
            display:flex;
            flex-direction: column;
        }
    }

    .row-max-participants {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 8px;
    }

    .row-max-user {
        display: grid;    
        grid-template-columns: 45% 55% auto;
        @media (max-width: 1030px){
            display:flex;
            flex-direction: column;
        }
    }

    .form {
        display: flex;
        /* width: 48%; */
        width: 100%;
        max-width: 500px;
        min-height: auto;
        flex-direction: column;
        padding: 20px 20px;
        border-radius: 8px;
        border-style: none;
        margin: 4px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);

            .field-user-create {
            margin: 10px 0;

             input {
                padding: 15px 0;
            }
        }
            .select {
                padding: 13.8px;
               
            }

        small {
            margin-top: 20px;
            color: #5b5bff;

            &.error{
                margin-top: 0;
                text-align: right;
                color:red;
            }
        }

        &.increase-heigh-selected {
            min-height: 665px;
        }

        &.increase-heigh-new {
            min-height: 542px;
        }

        &.increase-heigh-selected-tec {
            min-height: 735px;
        }

        &.increase-heigh-new-tec {
            min-height: 614px;
        }
    }

    @media (max-width: 375px),
        (max-width: 600px) and (orientation: landscape),
        (max-width: 990px) and (orientation: landscape) {
        height: auto;
    }
 
    p {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        color: #383838;
        padding-bottom: 10px;
    }

    .css-1pahdxg-control {
        border-color: #3f51b5;
        box-shadow: 0 0 0 1px #3f51b5;
    }
    .teste{
        font-size: 1em;
    }
`;

