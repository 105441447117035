import styled from 'styled-components';

export const ContainerEngagement = styled.section`
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 425px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 150px;
                margin: 0 auto;
                
            }
    

    .engagement-card {
        display: flex;
        flex-direction: column;
        background-color: #D1D0D0;
        border-radius: 6px;
        flex-grow: 1;
        flex-basis: 22%;
        color: #282B3A;
        margin: 10px;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        min-width: 200px;
        


        .engagement-card-tooltip-area {
            position: relative;
            width: 100%;
            height: 2rem;
            display: flex;
            justify-content: flex-end;

        }

        .engagement-card-info {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                    font-size: 14px;
                }

                .main-icon {
                    width: 46px;
                    height: 46px;
                }
            }
        }
    }

    h4 {
        font-size: 22px;
        display: contents;
        margin-left: 2%;
    }

    p {
        font-size: 16px;
        display: contents;
        font-weight: 600;
    }

    .users-ratio {
        font-size: 12px;
        color: #eb7c6d;
    }

    .question {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .main-icon {
        border-radius: 30px;
        border: none;
        object-fit: none;
        width: 46px;
        height: 46px;
        background-image: linear-gradient(to left, #ffae46, #ff428d 100%);
        margin-right: 8%;
        margin-left: 8%;
    }

    .loading {
        z-index: 0;
        background: transparent;
    }
`;
