import styled from 'styled-components';

export const ItemWrapper = styled.ol`
    list-style: none;
    display: flex;
    align-items: center;
    color: #323232;
    padding-bottom: 8px;

    a {
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        color: #323232;
        font-weight: bold;
        text-decoration: none;
        &.current {
            margin-left: 4px;
            color: #ff8f63;
        }
    }

    .breadcrumb-separator {
        color: #323232;
        margin: auto 6px;
        user-select: none;
    }
`;
    