import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    display:flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
    background: #282b3a;

    @media(max-width: 600px) and (orientation: landscape) {
        height:calc(100% - 18%);
    }
    
    @media(max-width: 990px) and (orientation: landscape) {
        height:calc(100% - 18%);
    }

    .error-content {
        width: 100%;
        display:flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-flow: column wrap;

        h1 {
            font-size: 40px;
            font-weight: bold;
            color: #FF7E7E;
            text-align: center;

            @media(min-width: 500px) {
                font-size: 80px;
            }
        }

        p {
            font-size: 20px;
            color: #fff;
            text-align: center;
        }

        button {
            color: white;
            padding: 8px;
            margin-top: 30px;
            background-image: linear-gradient(90deg, #FE5F7A 0%, #F28F54 100%);
            width: 200px;
            border: 0;
            box-shadow: 0 0 0 0;
            cursor: pointer;
        }
    }
`;
