import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../layouts/dashboard';
import api from '../services/api';
export default function RouteWrapper({ component: Component, isPrivate, notFound, ...rest }) {
  const loggedIn = api.isLogged();

  if (!loggedIn && isPrivate) {
    return <Redirect to="/" />;
  }

  if (loggedIn && !isPrivate && !notFound) {
    return <Redirect to="/home" />;
  }

  if (notFound) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  let Layout = null;

  switch (rest.path) {
    case '/':
      Layout = AuthLayout;
      break;
    case '/recoverPassword/:voucherId?':
      Layout = AuthLayout;
      break;
    case '/codeVerification':
      Layout = AuthLayout;
      break;
    case '/newPassword/:t':
      Layout = AuthLayout;
      break;
    case '/successNewPassword':
      Layout = AuthLayout;
      break;
    case '/failedNewPassword':
      Layout = AuthLayout;
      break;
    default:
      Layout = DefaultLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = { isPrivate: false };
