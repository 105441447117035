import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
import UploadIcon from 'src/assets/cloud-icon.svg';
import UploadMultipleIcon from 'src/assets/cloud-icon.svg';
import { DropContainer, UploadMessage } from './styles';

const dropzoneRef = createRef();
export const openDialog = () => {
    if (dropzoneRef.current) {
        dropzoneRef.current.open();
    }
};

function Upload({ onUpload, multiple = false, accept = {'image/*': ['.png', '.jpeg', '.jpg']}, message = '', getIsFileRejected = null, handleCancelDialog,}) {

    const renderDragMessage = (isDragActive, fileRejections) => {
        if (!isDragActive) {
            return (
                <UploadMessage><img src={multiple ? UploadMultipleIcon : UploadIcon} alt="Upload" />
                    {message && (<small style={{ color: "#212B36" }}>{message}</small>)}
                </UploadMessage>
            );
        }

        if (fileRejections.lenght > 0) {
            return <UploadMessage type="error"> Erro de ao fazer upload </UploadMessage>;
        }

        return (
            <UploadMessage type="success">
                {
                    multiple
                        ? "Upload feito com sucesso"
                        : "Uploado feito com sucesso"
                }
            </UploadMessage>
        );
    };

    return (
        <>
            <Dropzone ref={dropzoneRef} multiple={multiple} accept={accept} onDropAccepted={onUpload} onFileDialogCancel={handleCancelDialog}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => (
                    <DropContainer
                        className="upload-content"
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                    >
                        <input multiple={multiple} {...getInputProps()} />
                        {renderDragMessage(isDragActive, fileRejections)}
                        {getIsFileRejected && getIsFileRejected(fileRejections)}
                    </DropContainer>
                )}
            </Dropzone>
        </>
    );
}

export default Upload;
