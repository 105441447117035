import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  CourseListHead,
  CourseListToolbar,
  VideoMoreMenu,
  CourseModal
} from '../../components/_dashboard/course';
// actions
import { getCourseVideos } from '../../store/modules/course/actions';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import Breadcrumbs from 'src/components/Breadcrumbs';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'courseId', label: 'Id', alignRight: false },
  { id: 'courseName', label: 'Nome da Sala', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    const filterId = filter(array, (_video) => _video.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    const filterName = filter(array, (_video) => _video.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);

    if (filterId.length) {
      return filterId
    }
    else if (filterName.length) {
      return filterName
    }
    else {
      return []
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CourseVideoList() {
  const dispatch = useDispatch();

  const params = useParams();
  const history = useHistory();

  const { courseVideos, isLoading } = useSelector(state => state.course);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('courseName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { breadcrumbs, setItems } = useBreadcrumbs();


  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const breadcrumbsItems = [
      { to: `/courses`, label: "Cursos" },
      { to: `/courseVideos/${selectedCourse}`, label: "Videos" },
    ];
    setItems(breadcrumbsItems);
  }, [setItems, selectedCourse]);

  useEffect(() => {
    setSelectedCourse(params?.courseId)
  }, [params])

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getCourseVideos(selectedCourse));
    }
  }, [selectedCourse]);


  const handleSaveCourse = (courseData) => {

    const { courseId, courseName, duration, courseTechnology, liveMachines } = courseData;

    if (!selectedCourse) {
      dispatch(createCourse(courseData));
    } else {
      dispatch(editCourse(courseId, courseName, duration, courseTechnology, liveMachines));
    }
    handleCloseModal();
  }

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedCourse(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value);
  };

  const handlePresenceReport = (courseName) => {
    history.push(`/presence-report?course=${courseName}?channelId=${params?.courseId}`);
  };

  const filteredCourses = applySortFilter(courseVideos, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredCourses.length) : 0;


  const isUserNotFound = filteredCourses.length === 0;

  return (
    <Page title="Courses">
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lista de Vídeos
          </Typography>
        </Stack>
        <Card>
          <CourseListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            caller='courseVideoList'
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CourseListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredCourses
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name } = row;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="right">
                            <VideoMoreMenu
                              onClick={() => handlePresenceReport(id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filterName && filteredCourses.length >= 0 ? filteredCourses.length : courseVideos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {isOpenModal && (
        <CourseModal
          course={selectedCourse}
          open={isOpenModal}
          title={`${selectedCourse ? 'Editar' : 'Criar'} Curso`}
          maxWidth='lg'
          onSave={courseData => handleSaveCourse(courseData)}
          onClose={() => handleCloseModal()}
        />
      )}
    </Page>
  );
}
