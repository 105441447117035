export default class BlockListEmail {
    constructor(api) {  
        this._api = api;
    }

    blockListEmail() {
        return this._api.get(`/admin/blocklist`).then(r => r.data.blocklist);
    }

    deleteEmailBlockList(email) {
        return this._api.delete("/admin/blocklist", {data: {
            emails: [email]
          }}).then(r => r.data)
    }
}