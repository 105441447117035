import styled from 'styled-components';

export const ContainerGraph = styled.section`
    height: 304px;
    background-color: ${props => (props.isVisible ? '#282b3a' : 'transparent')};
    margin: 0 4%;

    .filter-graph{
        display: inline-flex;
        
        flex-grow: 1;

            #filter-1{
                color: #282B3A;
                border-color: #ff9953;
            }

             #filter-2{
                color: #282B3A;
            }

            #vs{
                background-color: #D1D0D0;
                margin: 10px 0;
                line-height: 2.4 !important;
                padding: 0 10px;
                color: #ff9953;
            }
            .filter-graph1 {
                margin: 10px 0 10px 5px;
                ban
            }

            .filter-graph2 {
                margin: 10px 0;
            }
    }

    .button-filter {
        margin: auto;

        .dropdown-button {
            color: #282B3A;
            margin-right: 10px;
            background-color: #302d2d;
            height: 38px;
            display: flex;
            border: 0;

            span {
                font-size: 14px;
            }
        }

        p {
            width: inherit;
            text-align: center;
            margin: 0 15px;
            font-weight: 400;
            font-size: 16px; 
            line-height: 2.5 !important;
        }

        img {
            margin: auto;
            padding-right: 5px;
        }

        .selected {
            span {
                color: red;
            }
        }
    }
    
    .filter-graph-time {
        margin: 10px;
    }
    
    .header {
            background-color: #D1D0D0;
            justify-content: center;
            min-height: 55px;
            flex-wrap: wrap;
            h4 {
                line-height: 3;
                margin: 0 5px;
            }

            p {
                line-height: 3;
            }
    }

    .loading {
        z-index: 0;
        background: transparent;
    }

    .skeleton-container {
        display: flex;
    }

    .graph-content {
        margin-top: 1rem;
        background-color: #EFEFEF;
        border-radius: 8px;
        margin-bottom: 8px;
    }
`;
