import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({ className: 'dropzone' })`  
  border-radius:4px;
  cursor: pointer;

  transition: height 0.2s ease;
  
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

const messageColors = {
    default: '#999',
    error: '#e57878',
    success: '#78e5d5',
};
export const UploadMessage = styled.p`
  display:flex;
  flex-direction: column;
  color: ${props => messageColors[props.type || 'default']};
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  font-size: 12px;
  font-weight: bold;  
  color: #474783;
  text-transform: uppercase;

  img {
      margin-bottom: 8px;
  }
`;

export const UploadContainer = styled.div`
    width: 100%;
`;

export const UploadMultipleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const ClearButton = styled.button.attrs(() => ({ type: 'button' }))`
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
`;

export const FileFormatError = styled.div`
    padding: .4rem .3rem;
    color: #fff;
    font-size: 12px;
    background-color: #ff6363;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin: 0 .5rem;
    }
    
    p {
        font-weight: bold;
    }

`;

export const CropContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    max-height: 60vh;
    overflow-y: auto;
    
    img {
        max-height: 60vh;
    }

    .crop-component {
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    button {
        margin: 2rem 0;
        width: 100%;
        padding: 15px 0;
        border: 0;
        border-radius: 8px;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
    }
 
`;

export const FileListContainer = styled.div`
  width: 100%;
`;

export const FileListContent = styled.div`
  width: 100%;
  height: calc(200px - 6px);

  display: grid;
  grid-template-columns: 170px calc(100% - 170px);
  align-items: center;
  gap: 18px;
  padding: 0 10px;
  border-radius: 5px;
  color: #444;

  .contain-infos-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px 0;

    p {
        font-size: 16px;
        margin-bottom: 6px;
    }
  }

  .btn-delete {
    width: 180px;
    padding: 6px 10px;
    background: #ea0430;
    color: #fff;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    border-radius: 5px;

    :hover {
        background: #a90020;
    }

    .icon {
        margin-bottom: -3px;
        font-size: 18px;
    }
  }

  div.upload-info {
    width: 28px;
    height: 28px;

    position: absolute;
    right: 8px;

    button {
      background-color: #fcefe2;
      border-radius: 50%;
      padding: 7px 8px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #fdf9f5;
      }
    }
  }

  @media (max-width: 520px) {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
`;

export const Preview = styled.div`
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 

  @media (max-width: 520px) {
    margin-top: 10px;
  }
`;
