
export default class PresenceReport {
    constructor(api) {
        this._api = api;
    }

    async getPresenceReport({ roomId, nextToken: startingToken, rowsPerPage: pageSize, keyword: search }) {
        return this._api.get(`/rooms/${roomId}/report/presence`, { params: { startingToken, pageSize, search } }).then(r => r.data);
    }

    async getPresenceReport({ roomId, nextToken: startingToken, rowsPerPage: pageSize, keyword: search }) {
        return this._api.get(`/rooms/${roomId}/report/presence`, { params: { startingToken, pageSize, search } }).then(r => r.data);
    }
}
