const DEFAULT_STATE = {
    list: [],
    logs: '',
    config: {},
  };

export default function reducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case 'SET_ROOM_LIST':
      return { ...state, list: payload };
    case 'SET_LOGS':
      return { ...state, log: payload};
    case 'SET_CONFIGS':
      return { ...state, config: payload};
    default:
      return state;
  }
}  