import styled from 'styled-components';

export const Input = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label span {
        left: 100%;
        transform: translateX(-100%);
    }

`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 20px;
    /* background: ${({ isOn }) => (isOn ? 'linear-gradient(to right, #ff5f89 0%, #ff9b5e 100%)' : '#4f4d4d')}; */
    background: ${({ isOn }) => (isOn ? '#282B3A' : '#333360')} ;
    border-radius: 60px;
    position: relative;
    transition: background-color .2s;

    &:active span {
        width: 60px;
    }
`;

export const Span = styled.span`
    content: '';
    position: relative;
    left: 2px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    transition: 0.2s;
    /* background: ${({ isOn }) => (isOn ? '#ff5f89' : '#827578')}; */
    background: ${({ isOn }) => (isOn ? '#282B3A' : '#42426d')};
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`;
