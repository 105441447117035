import { Box, Button, DialogActions, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import Modal from "src/components/Modal";
import { addNewUserCourseSelection, saveUserCoursePermission, userGetCourses } from "src/store/modules/user/actions";
import Notify from "src/utils/notification";

export default function UsersCoursesModal({ data, open, title, onSave, onClose }) {
  const dispatch = useDispatch();

  const isNew = !data?.course_id;

  const [values, setValues] = useState({
    course: data ? data?.course_id : '',
    permission: data ? data?.permission : '',
  });

  const possibleCourses = useSelector(state => state.user.courses, shallowEqual);

  useEffect(() => {
    dispatch(userGetCourses());
  }, []);

  const handleSave = () => {
    if (isNew) {
      const payload = {
        selectedUser: {
          user_id: data?.user_id
        },
        selectedCourse: values?.course,
        selectedPermission: values?.permission
      };
  
      const {selectedUser, selectedCourse, selectedPermission} = payload;

      if (!selectedCourse) {
        Notify("Selecione algum curso");
        return;
      }
      if (!selectedPermission) {
        Notify("Selecione o tipo de permissão");
        return;
      }

      dispatch(addNewUserCourseSelection(selectedUser, selectedCourse, selectedPermission));
    } else {
      const payload = {
        selectedUser: {
          user_id: data?.user_id
        },
        course: {
          course_id: values?.course
        },
        selectedPermission: values?.permission
      };
  
      const {selectedUser, course, selectedPermission} = payload;

      if (!selectedPermission) {
        Notify("Selecione o tipo de permissão");
        return;
      }

      dispatch(saveUserCoursePermission(selectedUser, course, selectedPermission));
    }
    onSave();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Modal open={open} title={title} onClose={onClose}>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { width: '100%' } }}
        noValidate
        autoComplete="off"
      >
        <div>
          <FormControl sx={{ m: 1 }} fullWidth>
            <InputLabel id="course-select-label">Curso</InputLabel>
            <Select
              labelId="course-select-label"
              id="course-select"
              value={values?.course}
              label="Curso"
              onChange={handleChange('course')}
              disabled={!isNew}
            >
              {possibleCourses.map((c) => (
                <MenuItem
                  key={`${c}-possiblecourses-${c.courseId}`}
                  value={c.courseId}
                >
                  {c.courseId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }} fullWidth>
            <InputLabel id="permission-select-label">Permissão</InputLabel>
            <Select
              labelId="permission-select-label"
              id="permission-select"
              value={values?.permission}
              label="Permissão"
              onChange={handleChange('permission')}
            >
              <MenuItem value="organizer">Organizer</MenuItem>
              <MenuItem value="monitor">Monitor</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Box>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={() => handleSave()}>Salvar</Button>
      </DialogActions>
    </Modal>
  );
}