import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// material
import { Box } from '@mui/material';
// Actions
import { logout } from 'src/store/modules/auth/actions';

// ----------------------------------------------------------------------

Logout.propTypes = {
  sx: PropTypes.object
};

export default function Logout({ sx }) {
  const dispatch = useDispatch();

  function exitAction() {
    dispatch(logout());
  }

  return (
    <Box
      component="button"
      sx={{ display: 'inline-flex', border: 'none', cursor: 'pointer', background: 'none' }}
      onClick={exitAction}
    >
      <Box component="img" src="/static/icons/logout.svg" sx={{ width: 29, height: 30, ...sx }} />
    </Box>
  );
}
