export function getListVideo() {
  return {
    type: 'GET_LIST_VIDEO_TUTORIAL'
  };
};

export function setListVideo(list) {
  return {
    type: 'SET_LIST_VIDEO_TUTORIAL',
    payload: list
  };
};

export function ChangeStatusVideoTutorial(id, status) {
  return {
    type: 'CHANGE_STATUS_LIST_VIDEO_TUTORIAL',
    payload: {id , status}
  };
};

export function createVideo(video) {
  return {
    type: 'VIDEO_TUTORIAL_CREATE',
    payload: video
  };
}

export function editVideo(id, data) {
  return {
    type: 'VIDEO_TUTORIAL_EDIT',
    payload: {id , data}
  };
}