import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import blockListEmail from './blockListEmail/reducer';
import course from './course/reducer';
import live from './live/reducer';
import login from './auth/reducer';
import recoverPassword from './recoverPassword/reducer';
import room from './rooms/reducer'
import user from './user/reducer';
import presenceReport from "./presenceReport/reducer"
import videoTutorial from './videoTutorial/reducer'
import managerSettings from "./managerSettings/reducer"

const RootReducer = (hist) =>
  combineReducers({
    router: connectRouter(hist),
    blockListEmail,
    course,
    live,
    login,
    presenceReport,
    recoverPassword,
    room,
    user,
    videoTutorial,
    managerSettings
  });

export default RootReducer;
