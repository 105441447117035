import { takeLatest, takeLeading, put, all, throttle, select } from 'redux-saga/effects';
import api from '../../../services/api';
import Notify from '../../../utils/notification';
import { getCourses, setCourseList, getCourseVideosSuccess } from './actions';

function getStatus(error) {
  return error.response ? error.response?.data?.error_description : '';
}

function* create({ payload }) {
  const notify = Notify('Solicitando criação de usuário', true);
  try {
    yield api.course.create(payload);
    notify.success('Curso criado com sucesso.');
    yield put(getCourses());
  } catch (error) {
    console.log(error);
    notify.error(`Falha ao criar curso. ${getStatus(error)}`);
  }
}

function* list({ payload }) {
  let search = payload;
  if (search === undefined) {
    search = yield select((state) => state.course.search);
  }
  if (search && search.length < 3) {
    return;
  }
  const notify = Notify('Carregando lista de cursos', true);
  try {
    const list = yield api.course.courses(search);
    yield put(setCourseList(list));
    notify.success('Lista adquirida');
  } catch (error) {
    notify.error(`Falha ao solicitar lista de cursos ${getStatus(error)}`);
  }
}

function* edit({ payload }) {
  const notify = Notify('Editando dados...', true);
  try {
    const { id, duration, courseTechnology, interactive } = payload;
    let { maxClients } = payload;
    const technology = parseInt(courseTechnology, 10);
    maxClients = parseInt(maxClients, 10);

    if (technology === 0 ) {
      maxClients = undefined;
    }
    yield api.course.editCourse(id, parseInt(duration, 10), technology, maxClients, interactive);
    notify.success('Curso editado com sucesso.');
    yield put(getCourses());
  } catch (error) {
    notify.error(`Falha ao editar curso. ${getStatus(error)}`);
  }

}

function* getCourseVideos({payload}) {
  const notify = Notify('Buscando videos...', true, 1000);
  try {
    const response = yield api.course.getCourseVideos(payload)
    yield put(getCourseVideosSuccess(response.rooms))
    notify.success("Busca de videos com sucesso.")
  } catch (error) {
    notify.error(`Falha ao obter videos do curso. ${getStatus(error)}`);
    
  }
}

export default all([
  takeLeading('COURSE_CREATE', create),
  takeLeading('COURSE_EDIT', edit),
  takeLatest('GET_COURSES', list),
  takeLatest('COURSE_VIDEOS_LIST_REQUEST', getCourseVideos),
  throttle(500, 'COURSE_SEARCH', list)
]);
