import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';
// routes
import Routes from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
//hooks
import { BreadcrumbsProvider } from './hooks/useBreadcrumbs';

import './config/ReactotronConfig';

import history from './services/history';
import { store } from './store';

// ----------------------------------------------------------------------

function GlobalSnackQueue() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  window.enqueueSnackbar = enqueueSnackbar;
  window.closeSnackbar = closeSnackbar;

  return null;
}

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={5}>
        <BreadcrumbsProvider>
          <Router history={history}>
            <ThemeConfig>
              <ScrollToTop />
              <GlobalStyles />
              <GlobalSnackQueue />
              <Routes />
            </ThemeConfig>
          </Router>
        </BreadcrumbsProvider>
      </SnackbarProvider>
    </Provider>
  );
}
