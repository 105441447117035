import { Icon } from '@iconify/react';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter } from 'lodash';
// material
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, Container, Typography, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import removeVideoFill from '@iconify/icons-eva/video-off-outline';
import addVideoFill from '@iconify/icons-eva/video-fill';
import editIcon from '@iconify/icons-eva/edit-outline';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import ListHead from '../../components/ListHead';
import ListToolbar from '../../components/ListToolbar';
import Image from 'src/components/Image';
import { getListVideo, ChangeStatusVideoTutorial } from '../../store/modules/videoTutorial/actions';
import DropdownTableMenu from 'src/components/DropdownTableMenu';
import VideoTutorialModal from '../../components/_dashboard/videoTutorial/VideoTutorialModal';
import { TableContainers } from "./styles";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_video) => _video.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
};

export default function TutorialVideo() {
    const dispatch = useDispatch();

    const { list: VIDEOLIST } = useSelector(state => state.videoTutorial);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('email');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [editVideoTutorials, setEditVideoTutorials] = useState(false);
    const [editVideoTutorialsData, setEditVideoTutorialsData] = useState({});

    const TABLE_HEAD = [
        { id: 'image', label: 'Thumbnail', alignRight: false },
        { id: 'name', label: 'Nome', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
    ];

    const StyledTableRow = styled(TableRow)(() => ({
        [`&.MuiTableRow-hover`]: {
            cursor: 'pointer'
        },
    }));

    useEffect(() => {
        dispatch(getListVideo());
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - VIDEOLIST.length) : 0;

    const filteredListVideo = applySortFilter(VIDEOLIST, getComparator(order, orderBy), filterName);

    const isListvideoTutorialNotFound = filteredListVideo.length === 0;

    const changeSatusVideoList = (row) => {
        const { id, enabled } = row;
        dispatch(ChangeStatusVideoTutorial(id, !enabled));
    };

    const editVideoList = (row) => {
        setIsOpenModal(true);
        setEditVideoTutorials(true);
        setEditVideoTutorialsData(row)
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
        setEditVideoTutorials(false);
    };

    const buildOptions = useCallback((row) => {
        const options = [
            {
                label: 'Editar vídeo de tutorial',
                icon: editIcon,
                width: 350,
                action: () => editVideoList(row)
            },
            {
                label: row?.enabled ? 'Desativar vídeo de tutorial' : row?.status === "ready" ? "Ativa vídeo de tutorial": "O vídeo ainda não pode ser ativado" ,
                icon: row?.enabled ? removeVideoFill : row?.status === "ready" ?  addVideoFill: removeVideoFill,
                width: 350,
                action: () => changeSatusVideoList(row)
            }
        ]
        return options
    }, []);

    return (
        <Page title="Lista de tutoriais de vídeo">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Lista de tutoriais de vídeo
                    </Typography>
                    <Button
                        variant="contained"
                        component="button"
                        startIcon={<Icon icon={plusFill} />}
                        onClick={() => setIsOpenModal(!isOpenModal)}
                    >
                        Novo video
                    </Button>
                </Stack>
                <Card>
                    <ListToolbar
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        caller={'Buscar por nome...'}
                    />
                    <Scrollbar>
                        <TableContainers>
                            <Table>
                                <ListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    onRequestSort={handleRequestSort}
                                    caller='video-tutorial-list'
                                />
                                <TableBody>
                                    {filteredListVideo
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, key) => {
                                            const { status, name, image, enabled } = row;
                                            return (
                                                <StyledTableRow
                                                    hover
                                                    key={key}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                >
                                                    <TableCell align="center">
                                                        <Image image={image} width={120} height={120} />
                                                    </TableCell>
                                                    <TableCell align="left">{name}</TableCell>
                                                    <TableCell className='textStatus' align="left">{enabled ? status : "disabled"}</TableCell>
                                                    <TableCell align="right">
                                                        <DropdownTableMenu options={buildOptions(row)} />
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isListvideoTutorialNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainers>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={VIDEOLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            {isOpenModal && (
                <VideoTutorialModal
                    open={isOpenModal}
                    openEdit={editVideoTutorials}
                    editVideoTutorialsData={editVideoTutorialsData}
                    title={editVideoTutorials ? "Editar Tutorial de Vídeo" : "Adicionar Tutorial de Vídeo"}
                    maxWidth='sm'
                    onClose={() => handleCloseModal()}
                />
            )}
        </Page>
    );
};
