import {
  takeLatest,
  takeLeading,
  put,
  all,
  throttle,
  select,
} from "redux-saga/effects";
import api from "../../../services/api";
import Notify from "../../../utils/notification";
import {
  getNextPagePresenceReportFail,
  getNextPagePresenceReportSuccess,
  getPresenceReportFail,
  getPresenceReportSuccess,
} from "./actions";

function getStatus(error) {
  return error.response ? error.response?.data?.error_description : "";
}

function* getPresenceReport({ payload: { roomId, rowsPerPage, keyword } }) {
  const notify = Notify("Buscando");
  try {
    const { nextToken, users } = yield api.presenceReport.getPresenceReport({
      roomId,
      rowsPerPage,
      keyword
    });
    yield put(getPresenceReportSuccess({ nextToken, users }));
    notify.success("Lista carregada");
  } catch (error) {
    yield put(getPresenceReportFail());
    notify.error(`Usuários não encontrados. ${getStatus(error)}`);
  }
}

function* getNextPagePresenceReport({ payload }) {
  const notify = Notify("Buscando");

  try {
    const res = yield api.presenceReport.getPresenceReport(payload);
    yield put(getNextPagePresenceReportSuccess(res));
  } catch (error) {
    yield put(getNextPagePresenceReportFail());
    notify.error(`Falha ao obter proxima pagina. ${getStatus(error)}`);
  }
}

export default all([
  // takeLeading('COURSE_CREATE', create),
  takeLatest("GET_PRESENCE_REPORT_REQUEST", getPresenceReport),
  takeLatest("GET_PRESENCE_REPORT_SUCCESS", getPresenceReportSuccess),
  takeLatest("GET_PRESENCE_REPORT_FAIL", getPresenceReportFail),
  takeLatest("PRESENCE_REPORT_NEXT_PAGE", getNextPagePresenceReport),
  takeLatest(
    "PRESENCE_REPORT_NEXT_PAGE_SUCCESS",
    getNextPagePresenceReportSuccess
  ),
  takeLatest("PRESENCE_REPORT_NEXT_FAIL", getNextPagePresenceReportFail),
  // throttle(500, 'COURSE_SEARCH', list)
]);
