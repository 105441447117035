import { takeLatest,put, all } from 'redux-saga/effects';

import api from '../../../services/api';
import {
    setRoomList,
    setLogs,
    setConfigs,
    getRooms,
} from './actions'
import Notify from '../../../utils/notification';

function getStatus(error) {
    return error.response ? error.response?.data?.error_description : '';
  }

function* list() {
    const notify = Notify('Carregando salas', true);
    try {
      const list = yield api.manager_settings.getRooms();
      yield put(setRoomList(list));
      notify.success('Salas carregadas');
    } catch (error) {
      notify.error(`Falha ao solicitar salas ${getStatus(error)}`);
    }
  }

function* logs() {
  const notify = Notify('Carregando logs', true);
  try{
    const log = yield api.manager_settings.getLogs();
    yield put(setLogs(log));
    notify.success('Logs carregados');
  } catch (error) {
    notify.error(`Falha ao solicitar logs ${getStatus(error)}`)
  }
}

function* configs() {
  const notify = Notify('Carregando configurações', true);
  try{
    const config = yield api.manager_settings.getConfigs();
    yield put(setConfigs(config));
    notify.success('Configurações carregadas');
  } catch (error) {
    notify.error(`Falha ao solicitar configurações ${getStatus(error)}`)
  }
}

function* edit(config) {
  const notify = Notify('Editando dados...', true);
  try {
    yield api.manager_settings.editConfigs(config);
    notify.success('Configurações editadas com sucesso.');
    yield put(getConfigs());
  } catch (error) {
    notify.error(`Falha ao editar configurações. ${getStatus(error)}`);
  }

}

function* deleteRoom(id) {
  const notify = Notify('Excluindo sala de forma permanente...', true);
  try {
    yield api.manager_settings.deleteRoom(id);
    notify.success('Sala excluída com sucesso.');
    yield put(getRooms());
  } catch (error) {
    notify.error(`Falha ao excluir sala. ${getStatus(error)}`);
  }
}

  export default all([
    takeLatest('GET_ROOMS', list),
    takeLatest('GET_LOGS', logs),
    takeLatest('GET_CONFIGS', configs),
    takeLatest('DELETE_ROOM', deleteRoom),
    takeLatest('EDIT_CONFIGS', edit)
  ]);
  