const getTechnology = (value) => {
    switch(value) {
      case 0:
      return 'Kaltura'
      case 1:
      return 'WebRTC'
      case 2:
      return 'RTMP'
      default:
      return ''
    }
  }

  export { getTechnology }