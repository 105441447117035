import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
import removeEmailFill from '@iconify/icons-eva/person-remove-outline';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import ListHead from '../../components/ListHead';
import ListToolbar from '../../components/ListToolbar';
// actions
import { getBlockListEmails, deleteEmailBlockList } from '../../store/modules/blockListEmail/actions';
import DropdownTableMenu from 'src/components/DropdownTableMenu';
import { blockListRefusedReasonTypes } from 'src/utils/constants';

const TABLE_HEAD = [
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'created_at', label: 'Data e Hora de entrada na lista de bloqueio', alignRight: false },
    { id: 'reason', label: 'Motivo', alignRight: false },
];

// ----------------------------------------------------------------------

const StyledTableRow = styled(TableRow)(() => ({
    [`&.MuiTableRow-hover`]: {
        cursor: 'pointer'
    },
}));

export const handleConvertDate = (date) => {
    const convertDate = new Date(date);
    const day = (`${convertDate.getUTCDate()}`).length === 2 ? convertDate.getUTCDate() : `0${convertDate.getUTCDate()}`;
    const mouth = (`${convertDate.getUTCMonth()}`).length === 2 ? convertDate.getUTCMonth()+1 : `0${convertDate.getMonth()+1}`;
    const year = `${convertDate.getUTCFullYear()}`;
    const hours = (`${convertDate.getUTCHours()}`).length === 2 ? convertDate.getUTCHours() : `0${convertDate.getUTCHours()}`;
    const minutes = (`${convertDate.getUTCMinutes()}`).length === 2 ? convertDate.getUTCMinutes() : `0${convertDate.getUTCMinutes()}`;
    return `${day}/${mouth}/${year} - ${hours}:${minutes}`
}

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_blocks) => _blocks.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function BlockListEmail() {
    const dispatch = useDispatch();

    const { list: EMAILLIST } = useSelector(state => state.blockListEmail);

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('email');
    const [filterEmail, setFilterEmail] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(getBlockListEmails());
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByEmail = (event) => {
        setFilterEmail(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - EMAILLIST.length) : 0;

    const filteredBlockListEmails = applySortFilter(EMAILLIST, getComparator(order, orderBy), filterEmail);

    const isBlockListEmailNotFound = filteredBlockListEmails.length === 0;

    const handleRemoveEmailBlockList = (row) => {
        const { email } = row;
        dispatch(deleteEmailBlockList(email));
    }

    const buildOptions = useCallback((row) => {
        const options = [
            {
                label: 'Excluir Email da Lista de Bloqueio',
                icon: removeEmailFill,
                width: 350,
                action: () => handleRemoveEmailBlockList(row)
            }
        ]
        return options
    }, [])

    const renderReason = (reason) => {
        return blockListRefusedReasonTypes[reason] || reason
    }

    return (
        <Page title="Block List Email">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Block List Email
                    </Typography>
                </Stack>
                <Card>
                    <ListToolbar
                        filterName={filterEmail}
                        onFilterName={handleFilterByEmail}
                        caller={'Busca por email'}
                    />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <ListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    onRequestSort={handleRequestSort}
                                    caller='block-list-email'
                                />
                                <TableBody>
                                    {filteredBlockListEmails
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const { email, reason, created_at } = row;
                                            return (
                                                <StyledTableRow
                                                    hover
                                                    key={email}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                >
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="left">{handleConvertDate(created_at)}</TableCell>
                                                    <TableCell align="left">{renderReason(reason) || ''}</TableCell>
                                                    <TableCell align="right">
                                                        <DropdownTableMenu options={buildOptions(row)} />
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isBlockListEmailNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterEmail} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={EMAILLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    )
}