import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: #5d595959;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    img {
        width: 250px;
        height: 180px;
        animation: rotate 1s linear infinite;
        border: none;
        background-attachment: fixed;
    }
    @keyframes rotate {
    to {
        transform: rotate(360deg);
    }
};
`;
