/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable indent */
import i18next from 'i18next';
import { appStoreLinks } from './constants';

const pattern = /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\\+\\[\],.~#?&\\/=]*[-a-zA-Z0-9@:%_\\+\]~#?&\\/=])*/i;

export const isEmpty = (obj) => {
    const { hasOwnProperty } = Object.prototype;
    if (obj === undefined) return true;
    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== 'object') return true;

    Object.keys(obj).forEach((key) => {
        if (hasOwnProperty.call(obj, key)) return false;
        return true;
    });

    return true;
};

export const toISOLocal = date => {
    const z = n => (`0${n}`).slice(-2);
    let off = date.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);

    return `${date.getFullYear()}-${z(date.getMonth() + 1)}-${z(date.getDate())}T${z(date.getHours())}:${z(date.getMinutes())}:${z(date.getSeconds())
        }${sign}${z(off / 60 | 0)}:${z(off % 60)}`;
};

export const translate = (key) => i18next.t(key);

export const patternUrl = new RegExp(pattern); // fragment locator

export const validURL = (str) => pattern.test(str);

export const getUrlFromString = (str) => (validURL(str) ? pattern.exec(str)[0] : '');

export const replaceUntil = (index, character, message) => character.repeat(index) + message.substr(index);

export const parseJwt = (token) => {
    if (!token) return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    // eslint-disable-next-line consistent-return
    return JSON.parse(jsonPayload);
};

export function limitCharacters(text, num) {
    if (num === 0) {
        return text;
    }

    if (text && num) {
        return text?.length > num ? `${text.substring(0, num--)}...` : text;
    }
    return '';
}

export const phoneMask = (value) => (
    value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')
);

export function isIOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

export function isSafari() {
    if (/apple/i.test(navigator.vendor)) {
        return true;
    }

    return false;
}

export const isAndroidDevice = () => navigator.userAgent.match(/Android/i);
export const isIOSDevice = () => navigator.userAgent.match(/iPhone|iPad|iPod/i);

export const checkMobileBrand = () => {
    if (isAndroidDevice()) {
        window.open(appStoreLinks.googlePlay, '_blank');
    }

    if (isIOSDevice()) {
        window.open(appStoreLinks.appleStore, '_blank');
    }
};

export const graphOptionsTranslate = (option) => {
    switch (option) {
        case "viewers":
            return "Usuários";
            break;
        case "viewers_buffering":
            return "Buffering de usuários";
            break;
        case "viewers_engagement":
            return "Engajamento de usuários";
            break;
        case "viewers_dvr":
            return "Usuários DVR";
            break;
        case "avg_view_downstream_bandwidth":
            return "Largura de banda de downstream";
            break;
        case "avg_view_bitrate":
            return "Média de taxa de bits";
            break;
        case "sum_view_time":
            return "Somatório de tempo de visualização";
            break;
        case "avg_view_live_latency":
            return "Latência média";
            break;
        case "avg_view_dropped_frames_ratio":
            return "Média de frames perdido";
            break;
        case "view_buffer_time_ratio":
            return "Tempo médio de buffer";
            break;
        case "avg_view_segment_download_time_sec":
            return "AVGVIEWSEGMENTDOWNLOADTIMESEC";
            break;
        case "avg_view_manifest_download_time_sec":
            return "AVGVIEWMANIFESTDOWNLOADTIMESEC";
            break;
        case "error":
            return "ERROR";
            break;
        default:
            return '';
    }
};
