const DEFAULT_STATE = {
  list: [],
  create: { id: '', name: '', duration: '' },
  search: '',
  courses: [],
  courseVideos: [],
  isLoading: false
};

export default function reducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case 'COURSE_SET_CREATE':
      return { ...state, create: payload };
    case 'COURSE_SET_LIST':
      return { ...state, list: payload };
    case 'COURSE_SEARCH':
      return { ...state, search: payload };
    case 'COURSE_SET_COURSES':
      return { ...state, courses: payload };
    case 'COURSE_VIDEOS_LIST_REQUEST':
      return {...state, isLoading: true}
    case 'COURSE_VIDEOS_LIST_SUCCESS':
      return {...state, isLoading: false, courseVideos: payload}
    case 'COURSE_VIDEOS_LIST_FAIL':
      return {...state, isLoading: false}
    default:
      return state;
  }
}
