const DEFAULT_STATE = {
  list: [],
  create: { name: '', fullname: '', username: '', password: '' },
  search: '',
  courses: [],
  passwordInfo: undefined,
  selectedUserCourses: [],
  selectedUser: {}
};

export default function reducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case 'USER_SET_CREATE':
      return { ...state, create: { ...payload } };
    case 'USER_SET_LIST':
      return { ...state, list: payload };
    case 'USER_SEARCH':
      return { ...state, search: payload };
    case 'USER_SET_COURSES':
      return { ...state, courses: payload };
    case 'USER_SHOW_NEW_PASSWORD':
      return { ...state, passwordInfo: payload };
    case 'USER_CLEAR_NEW_PASSWORD':
      return { ...state, passwordInfo: undefined };
    case 'SET_SELECTED_USER_COURSES':
      return { ...state, selectedUserCourses: payload.subscriptions };
    case 'SET_SELECTED_USER':
      return { ...state, selectedUser: payload };
    case 'CANCEL_COURSE_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        selectedUserCourses: payload
      };
    }

    default:
      return state;
  }
}
