import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import Upload from '../KopaUpload/Upload';
import CopyIcon from '../../assets/copy.svg';
import { MdOutlineDelete } from 'react-icons/md';
import { RecordedContainer, UrlContainer } from './styles';

const RecordedVideo = ({ playUrl, message, handleFileCallback }) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const [uploadVideo, setUploadVideo] = useState();
    const [videoUrl, setVideoUrl] = useState('');

    const primaryClickHandle = () => {
        navigator.clipboard.writeText('').then(
            setOpenTooltip(true),
        );
    };
    const handleUpload = ([file]) => {
        setUploadVideo({ file, uploaded: true, name: file.name, preview: URL.createObjectURL(file) });
        setVideoUrl(URL.createObjectURL(file));
    };
    
     useEffect(() => {
         if (typeof handleFileCallback === 'function') {
             handleFileCallback(uploadVideo);
         }
     }, [uploadVideo, handleFileCallback]);

    return (
        <RecordedContainer>
            <div className='contain-update-video'>
                <h4> Upload de vídeo</h4>
                {(videoUrl && uploadVideo) ? 
                    ( 
                        <>
                            <video
                                className="VideoInput_video"
                                width="100%"
                                height="200px"
                                controls
                                src={videoUrl}
                            />
                            <p><b>Nome: </b>{uploadVideo.name}</p>

                            <button className='btn-delete' type='button' onClick={() => setUploadVideo()}><MdOutlineDelete className='icon' /> Excluir Vídeo</button>
                        </>
                    )
                    :   
                    (
                        <div className="upload">
                            { (!uploadVideo) && <Upload onUpload={handleUpload} accept={{'video/*': ['.mp4', '.mov', '.mkv']}} message={message} />}
                        </div>
                    )}
            </div>

            {playUrl
                && (
                    <UrlContainer>
                        <h3>CHANNEL: </h3>
                        <span />
                        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                            <Tooltip
                                id="tooltip"
                                PopperProps={{ disablePortal: true }}
                                onClose={() => setOpenTooltip(false)}
                                open={openTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={'COPIED'}
                            >
                                <a href="#/"><img onClick={primaryClickHandle} alt="Copy Icon" className="copy-icon" src={CopyIcon} /> </a>
                            </Tooltip>
                        </ClickAwayListener>
                    </UrlContainer>
                )}
        </RecordedContainer>
    );
};

export default RecordedVideo;
