export default class Course {
    constructor(api) {
        this._api = api;
    }

    courses(keyword) {
        return this._api.get(`/courses${keyword ? `?keyword=${keyword}` : '/'}`).then(r => r.data.courses);
    }

    editCourse(courseId, duration, courseTechnology, maxClients, interactive) {
        return this._api.put(`/courses/${courseId}`, { duration, courseTechnology, maxClients, interactive }).then(r => r.data)
    }

    createCourse(courseId, courseName, duration) {
        return this._api.post(`/courses/`, { courseId, courseName, duration }).then(r => r)
    }

    create(data) {
        return this._api.post(`/courses/`, data).then(r => r)
    }

    getCourseVideos(courseId) {
        return this._api.get(`/rooms/course/${courseId}`).then(r => r.data)
    }

    getCourseInfo(courseId) {
        return this._api.get(`/courses/${courseId}`).then(r => r.data)
    }

}