export const loginRequest = (username, password) => ({
  type: 'LOGIN_REQUEST',
  payload: { username, password }
});

export const loginSuccess = (token) => ({
  type: 'LOGIN_SUCCESS',
  payload: { token }
});

export const loginFailed = (error) => ({
  type: 'LOGIN_FAILED',
  payload: { error }
});

export const logout = () => ({ type: 'LOGOUT' });

export function clearAll() {
  return { type: 'CLEAR_ALL' };
}
