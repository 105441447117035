import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import tvFill from '@iconify/icons-eva/tv-fill';
import blockFill from '@iconify/icons-eva/close-square-outline';
import settingsOutline from '@iconify/icons-eva/settings-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'lives',
    path: '/lives',
    icon: getIcon(tvFill)
  },
  {
    title: 'usuários',
    path: '/users',
    icon: getIcon(peopleFill)
  },
  {
    title: 'cursos',
    path: '/courses',
    icon: getIcon(videoFill)
  },
  {
    title: 'Block List Email',
    path: '/block-list-email',
    icon: getIcon(blockFill)
  },
  {
    title: 'Video Tutorial',
    path: '/video-tutorial',
    icon: getIcon(videoFill)
  },
  {
    title: 'Manager',
    path: '/manager-settings',
    icon: getIcon(settingsOutline)
  }
];

export default sidebarConfig;
