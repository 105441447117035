import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
// Material UI
import {
    Card,
    Stack,
    Button,
    Typography,
  } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';
// components
import Scrollbar from '../../Scrollbar';
// actions
import { getConfigs, editConfig } from '../../../store/modules/managerSettings/actions';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
// ---------------------------------------------------------------------------------------------------------------
const StyledTextArea = styled(TextareaAutosize)(() => ({
  width:'97%',margin: '1em', minHeight: '90%'
}));


export default function ManagerConfigs(){
   const dispatch = useDispatch();

   const { config: CONFIG } = useSelector(state => state.managerSettings);
 
   const {breadcrumbs, setItems } = useBreadcrumbs();
   const [editMode,setEditMode] = useState(false);
   const [config, setConfig] = useState({})

   useEffect(() => {
     const breadcrumbsItems = [
       { to: `/manager-settings`, label: "Manager" },
     ];
     setItems(breadcrumbsItems);
   }, [setItems]);
 
   useEffect(() => {
     dispatch(getConfigs());
   }, []);

   useEffect(() => {
    if(CONFIG){
      setConfig(JSON.stringify(CONFIG, undefined, 4));
    }
  }, []);

    return (
        <>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={2}>
                <Typography variant="h4" gutterBottom>
                    Configurações
                </Typography>
                {!editMode ? 
                (
                  <Button
                  variant="contained"
                  component="button"
                  startIcon={<Icon icon={plusFill} />}
                  onClick={() => setEditMode(true)}
                > 
                  Editar Configurações
                </Button>

                ) : ('')}
                
              </Stack>
              <Card>
                <Scrollbar>
                {!editMode ? 
                  (
                    <StyledTextArea
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Maximum 4 rows"
                      defaultValue={config}
                      disabled
                      />
                  ) 
                : 
                  (
                      <StyledTextArea
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Maximum 4 rows"
                      value={config}
                      onChange={(e)=> setConfig(e.target.value)}
                      defaultValue={config}
                      />
                  )}  
                </Scrollbar>
                <Stack direction="row" alignItems="center" justifyContent="end" m={2} mt={0}>
                {editMode ? 
                (
                  <Button
                  variant="contained"
                  component="button"
                  
                  startIcon={<Icon icon={checkmarkCircle2Outline} />}
                  onClick={() => {
                    dispatch(editConfig(JSON.parse(config)))
                    setEditMode(false)
                  }}
                >
                  Confirmar Alterações
                </Button>

                ) : ('')}
                
              </Stack> 
              </Card>
            </>
    )
}
