import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Breadcrumbs from '../../components/Breadcrumbs';
import { UserListHead, UserListToolbar, UsersCoursesModal } from '../../components/_dashboard/user';
// actions
import { getUserById, getUserCourses } from 'src/store/modules/user/actions';
import UserCourseMoreMenu from 'src/components/_dashboard/user/UserCourseMoreMenu';
import { useQuery } from 'src/hooks/useQuery';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'course_id', label: 'Curso', alignRight: false },
  { id: 'permission', label: 'Permissão', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.course_id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UsersCoursesList() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [user, setUser] = useQuery('user');
  const { breadcrumbs, setItems } = useBreadcrumbs();

  const selectedUserCourses = useSelector(state => state.user.selectedUserCourses);
  const selectedUser = useSelector(state => state.user.selectedUser);

  useEffect(() => {
    const breadcrumbsItems = [
      { to: `/users`, label: 'Usuários' },
      { to: '#', label: 'Lista de Cursos' }
    ];
    setItems(breadcrumbsItems);
  }, [setItems]);

  useEffect(() => {
    if (!user && selectedUser) {
      setUser(selectedUser?.user_id);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (user) {
      dispatch(getUserCourses(user));
      dispatch(getUserById(user));
    }
  }, [user]);

  const handleEditCourse = row => {
    setSelectedCourse({ ...row, ...selectedUser });
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedCourse({});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - selectedUserCourses.length) : 0;

  const filteredUsersCourses = applySortFilter(selectedUserCourses, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsersCourses.length === 0;

  return (
    <Page title="UsersCourses">
      <Container>
        <Breadcrumbs
          items={breadcrumbs}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {selectedUser?.fullname}
          </Typography>
          <Button
            variant="contained"
            component="button"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setIsOpenModal(true)}
          >
            Adicionar Curso
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            caller={'userList'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsersCourses
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { course_id: id, permission } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">{permission}</TableCell>
                          <TableCell align="right">
                            <UserCourseMoreMenu
                              onEdit={() => handleEditCourse(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={selectedUserCourses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {isOpenModal && (
        <UsersCoursesModal
          data={{ ...selectedCourse, user_id: user || selectedUser?.user_id }}
          open={isOpenModal}
          title={`${selectedCourse.course_id ? 'Editar' : 'Criar'} Curso`}
          onClose={() => handleCloseModal()}
          onSave={() => handleCloseModal()}
        />
      )}
    </Page>
  );
}
