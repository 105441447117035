export default class ManagerSettings {
    constructor(api) {
        this._api = api;
    }

    getLogs(){
        return this._api.get(`${process.env.REACT_APP_MANAGER}admin/logs`).then(r => {
            return r.data
            }
        );
    }

    getRooms(){
        return this._api.get(`${process.env.REACT_APP_MANAGER}admin/rooms/statuses`).then(r => {
            return r.data
            }
        ); 
    }
    
    getConfigs(){
        return this._api.get(`${process.env.REACT_APP_MANAGER}admin/config`).then(r => {
            return r.data
            }
        ); 
    }

    editConfigs({payload: config}){
        return this._api.patch(`${process.env.REACT_APP_MANAGER}admin/config`,config)
    }
    
    deleteRoom({payload: id}) {
        return this._api.post(`${process.env.REACT_APP_MANAGER}admin/rooms/${id}/clean`).then(r => r.data)
    }
}