import styled from 'styled-components';
import { DialogActions } from "@mui/material";

export const CreateCourseContent = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0px 25px 50px;

    .--modal-body{
        width: 100%;
        padding: 20px;
    }

    .confirm-buttons button.cancel {
        width: auto;
    }
`;

export const TutorialVideoForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box; 
    padding: 18px;
    border-radius: 8px;
    background-color: #fff;

    .input-title {
        width: 100%;
        margin: 0;
    }
`;

export const TutorialVideoFormUploadArea = styled.div`
    width: 100%;

    h4 {
        margin-bottom: 5px;
        margin-top: 20px;
    }

    .upload-content {
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const TutorialFormUploadDropzone = styled.div`
    width: 100%;
    height: 200px;
    border: 1px solid rgb(0, 0, 0, 0.15);
    border-radius: 8px; 
    display: flex;
    justify-content: center;
    align-items: center;

    .dropzone {
        flex-grow: 1;
    }

    @media (max-width: 520px) {
        height: auto;
    }
`;

export const TutorialVideoFormUploadHeader = styled.div`
    span {
        color: #ff9869;
        font-size: 10px;
    }
`;

export const CreateCourseHeader = styled.div`
    display: flex;
    justify-content: space-between;

    .header-buttons-holder {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    & > div {
        width: 250px !important;

        button {
            width: 100% !important;

            img {
                transform: rotate(180deg);
            }
        }
    }
`;

export const CreateCourseHeaderTitle = styled.h1`
    color: #fff;
`;

export const CreateCourseFormArea = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    max-height: 814px;
    height: 90%;
`;

export const DialogAction = styled(DialogActions)`
    width: 100%;
    margin-top: 10px;
`;