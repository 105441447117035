import history from '../services/history';

/**
 * @param {any} error
 * @param {{default: string, [number]: string}} handlers [status]: message, default: message
 */
export function defaultErrorHandler(error, handlers) {
  let message = 'Sem acesso a internet';

  if (error.response) {
    const { status } = error.response;
    message = handlers[status];
    if (!message) {
      message = handlers.default;
    }

    if (status === 401) {
      history.push('/forcedLogout');
    }
  }

  return message;
}
