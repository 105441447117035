import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router';

export function useQuery(param, validation = () => true) {
  const loc = useLocation();
  const hist = useHistory();
  const valueInUrl = new URLSearchParams(loc.search).get(param);

  useEffect(() => {
    if (valueInUrl && !validation(valueInUrl)) {
      const params = new URLSearchParams(hist.location.search);
      params.delete(param);
      hist.replace(`${hist.location.pathname}?${params.toString()}`);
    }
  }, [valueInUrl, hist, param, validation]);

  const value = valueInUrl && validation(valueInUrl) ? valueInUrl : undefined;

  function setValue(newValue) {
    const params = new URLSearchParams(hist.location.search);
    if (newValue === '' || newValue === null || newValue === undefined || !validation(newValue)) {
      params.delete(param);
    } else {
      params.set(param, newValue);
    }
    hist.replace(`${hist.location.pathname}?${params.toString()}`);
  }

  return [value, setValue];
}
