import axios from 'axios';
import User from 'src/store/modules/user/api';
import BlockListEmail from 'src/store/modules/blockListEmail/api';
import Course from 'src/store/modules/course/api';
import RecoverPassword from 'src/store/modules/recoverPassword/api'
import Live from 'src/store/modules/live/api';
import Rooms from 'src/store/modules/rooms/api'
import PresenceReport from 'src/store/modules/presenceReport/api';
import VideoTutorial from "src/store/modules/videoTutorial/api"
import config from 'src/utils/config';
import ManagerSettings from 'src/store/modules/managerSettings/api';

class Api {
  constructor() {
    this._baseURL = process.env.REACT_APP_BASE_API;
    this._api = axios.create({
      baseURL: this._baseURL,
      timeout: 60000
    });
    this._token = sessionStorage.getItem(config.storageToken);
    this._api.defaults.headers.common['Authorization'] = `Bearer ${this._token}`;
    this._session = null;

    this.blockListEmail = new BlockListEmail(this._api);
    this.course = new Course(this._api);
    this.live = new Live(this._api);
    this.user = new User(this._api);
    this.rooms = new Rooms(this._api);
    this.recover_password = new RecoverPassword(this._api)
    this.presenceReport = new PresenceReport(this._api);
    this.videoTutorial = new VideoTutorial(this._api);
    this.manager_settings = new ManagerSettings(this._api);

  }

  // eslint-disable-next-line class-methods-use-this
  isLogged() {
    return !!this._token;
  }

  getAccessToken() {
    return this._token;
  }

  getSessionToken() {
    return this._session.session_token;
  }

  async login(username, password) {
    const { data: token } = await this._api.post('auth/login', { username, password });
    sessionStorage.setItem(config.storageToken, token.accessToken);
    this._token = token.accessToken;
  }

  async logout() {
    try {
      if (this._token) {
        await this._api.post('auth/logout');
      }
    } finally {
      this.clear();
    }
  }

  async me() {
    this._api.defaults.headers.common['Authorization'] = `Bearer ${this._token}`;

    const { data } = await this._api.get('/auth/me');
    return data;
  }

  clear() {
    this._token = null;
    sessionStorage.removeItem(config.storageToken);
  }

  async exportPresenceReport(roomId) {
    return this._api.get(`/rooms/${roomId}/report/presence/export`, { responseType: 'blob' });
}
}

const api = new Api();

export default api;
