const INITIAL_STATE = {
  error: false,
  loading: false,
  loggedIn: false,
  token: ''
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return { ...state, loading: true, error: null };
    case 'LOGIN_SUCCESS':
      return {
        loading: false,
        error: false,
        loggedIn: true,
        token: action.payload.token
      };
    case 'LOGIN_FAILED':
      return { ...state, loading: false, error: action.payload.error };

    case 'CLEAR_ALL':
    case 'LOGOUT':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
