import React from 'react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// Material UI
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TablePagination,
  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
  // components
import Scrollbar from '../../Scrollbar';
import {
  RoomListToolbar,
} from '../../_dashboard/manager';

// actions
import { getRooms } from '../../../store/modules/managerSettings/actions';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import ListHead from 'src/components/ListHead';
import SearchNotFound from 'src/components/SearchNotFound';
import ManagerRoomModal from 'src/components/_dashboard/manager/ManagerRoomModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'roomId', label: 'Id', alignRight: false },
  { id: 'roomStatus', label: 'Status da sala de exibição', alignRight: false },
  { id: 'actions', label: 'Ações', alignRight: false},
];

// ----------------------------------------------------------------------

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
      return -1;
  }
  if (b[orderBy] > a[orderBy]) {
      return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
  });
  if (query) {
    const filterId = filter(array, (_rooms) => _rooms.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    const filterStatus = filter(array, (_rooms) => _rooms.status.toLowerCase().indexOf(query.toLowerCase()) !== -1);

    if (filterId.length) {
      return filterId
    }
    else if (filterStatus.length) {
      return filterStatus
    }
    else {
      return []
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

// ---------------------------------------------------------------------------------------------------------------
  const StyledTableRow = styled(TableRow)(() => ({
    [`&.MuiTableRow-hover`]: {
        cursor: 'pointer'
    },
  }));

export default function ManagerRooms(){
    const dispatch = useDispatch();

    const { list: ROOMLIST } = useSelector(state => state.managerSettings);
 
    const {breadcrumbs, setItems } = useBreadcrumbs();
    const [filterStatus, setFilterStatus] = useState('');
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('roomName');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRoom, setSelectedRoom] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
 
   useEffect(() => {
     const breadcrumbsItems = [
       { to: `/manager-settings`, label: "Manager" },
     ];
     setItems(breadcrumbsItems);
   }, [setItems]);
 
   useEffect(() => {
     dispatch(getRooms());
   }, []); 
 
   const handleRequestSort = (event, property) => {
     const isAsc = orderBy === property && order === 'asc';
     setOrder(isAsc ? 'desc' : 'asc');
     setOrderBy(property);
   };
 
   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
 
   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPage(0);
   };
 
   const handleFilterByStatus = (event) => {
     setPage(0);
     setFilterStatus(event.target.value);
   };
   
   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ROOMLIST.length) : 0;

   const filteredRoomList = applySortFilter(ROOMLIST, getComparator(order, orderBy), filterStatus);
 
   const isRoomListNotFound = filteredRoomList.length === 0;
 
    return (
        <>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={2}>
                <Typography variant="h4" gutterBottom>
                  Lista de Salas <Icon 
                        icon={refreshFill}
                        onClick={() => dispatch(getRooms())} />
                </Typography>
              </Stack>
              <Card>
                <RoomListToolbar
                  filterName={filterStatus}
                  onFilterName={handleFilterByStatus}
                  caller={'status'} />
                <Scrollbar>
                  <Table>
                    <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                    caller='room-list'
                    /> 
                  <TableBody>
                      {filteredRoomList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                        const { id, status } = row;
                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">{status}</TableCell>
                          <TableCell align="left">
                            <Icon 
                              icon={trash2Fill}
                              width={20}
                              height={20}
                              style={{marginLeft: '0.7em'}}
                              onClick={() => {
                                setSelectedRoom(row);
                                setIsOpenModal(true);
                                }
                              } 
                            />
                          </TableCell>
                          </StyledTableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isRoomListNotFound && (
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterStatus} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    )}
                  </Table>
                </Scrollbar>
                <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ROOMLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
              </Card>
              {isOpenModal && (
                <><h1>Teste</h1>
                <ManagerRoomModal 
                  room={selectedRoom}
                  title={selectedRoom.id}
                  open={isOpenModal}
                  maxWidth='xs'
                  onClose={() => setIsOpenModal(false)} /></>
              )}
            </>
    )
}
